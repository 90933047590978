/* eslint-disable @typescript-eslint/no-unsafe-assignment */
/* eslint-disable @typescript-eslint/no-unsafe-member-access */
import { Address } from './utils/address';
import { Picture } from './picture';
import { ModelElement } from './model-element';

export class Space {
  id: string;
  revision: number;
  name: string;
  description: string;
  logo: Picture;
  emailAddress: string;
  websiteUrl: string;
  phoneNumber: string;
  address: Address;
  currency: string;
  reportColor: string;
  is2FAFeatureAvailable: boolean;
  is2FAEnabled: boolean;
  is2FAStrictModeEnabled: boolean;
  subscriptionVersion: number;

  constructor(
    id: string = ModelElement.generateId(),
    revision: number = 0,
    name: string = '',
    description: string = '',
    emailAddress: string = '',
    websiteUrl: string = '',
    phoneNumber: string = '',
    address: Address = new Address(),
    currency: string = 'GBP',
    reportColor: string = '',
    is2FAFeatureAvailable: boolean = false,
    is2FAEnabled: boolean = false,
    is2FAStrictModeEnabled: boolean = false,
  ) {
    this.id = id;
    this.revision = revision;
    this.name = name;
    this.description = description;
    this.emailAddress = emailAddress;
    this.websiteUrl = websiteUrl;
    this.phoneNumber = phoneNumber;
    this.address = address;
    this.currency = currency;
    this.reportColor = reportColor;
    this.is2FAFeatureAvailable = is2FAFeatureAvailable;
    this.is2FAEnabled = is2FAEnabled;
    this.is2FAStrictModeEnabled = is2FAStrictModeEnabled;
  }

  /**
   * Convert backend data to Model
   * @param json The given backend Object
   * @param model The given model
   */
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  public static toModel(json: any, model: Space) {
    model.id = json.id;
    model.name = json.name;
    model.description = json.description;
    model.logo = new Picture();
    if (json.thumbnailLogo) {
      model.logo.id = json.thumbnailLogo;
    }
    model.emailAddress = json.emailAddress;
    model.websiteUrl = json.websiteUrl;
    model.phoneNumber = json.phoneNumber;
    if (json.address !== null) {
      model.address = json.address;
    }
    if (json.currency !== null) {
      model.currency = json.currency;
    }
    if (json.reportColor !== null) {
      model.reportColor = json.reportColor;
    }
    model.is2FAFeatureAvailable = json.is2FAFeatureAvailable;
    model.is2FAEnabled = json.is2FAEnabled;
    model.is2FAStrictModeEnabled = json.is2FAStrictModeEnabled;
    model.subscriptionVersion = json.subscriptionVersion
  }

  public static sortByName(spaces: Space[]): Space[] {
    return spaces.sort((a, b) => a.name.toLowerCase() > b.name.toLowerCase() ? 1 :
      b.name.toLowerCase() > a.name.toLowerCase() ? -1 : 0);
  }

  /**
   * Convert front end model to back end dto model
   */
  toDTO() {
    return {
      id: this.id,
      name: this.name,
      description: this.description,
      logo: this.logo,
      emailAddress: this.emailAddress,
      websiteUrl: this.websiteUrl,
      phoneNumber: this.phoneNumber,
      address: this.address,
      currency: this.currency,
      reportColor: this.reportColor,
      is2FAEnabled: this.is2FAEnabled,
      is2FAStrictModeEnabled: this.is2FAStrictModeEnabled
    };
  }
}
