<div *ngIf="!deviceIsMobile" [ngClass]="{'empty-field': parentFormGroup.value.materials.length ===0, 'top-margin': isEdited}" class="custom-field-web  select-multiple" [class.hide-fields]="!isEdited && isEmpty('materials')">
  <app-select-multiple-assets [assetCategory]="getAssetCategory().MATERIALS" [parentFormGroup]="parentFormGroup" controlName="materials" [items]="materials"
                [isReadOnly]="!isEdited" [isLoading]="!materialsLoaded" [hasDuration]="false" [isSiteDiary]="true"
                placeholder="&nbsp;+&nbsp;&nbsp;&nbsp;{{ 'events.edit.field.material.label' | translate }}">
  </app-select-multiple-assets>
</div>

<div *ngIf="deviceIsMobile && !isMobileEventPreviewComponent" class="custom-field-mobile">
  <ion-item
    class="card-items"
    lines="none"
    (click)="openItemSelectorPopup(materials, parentFormGroup.value.materials, 'materials', 'multiple_with_quantity')">
    <ion-input
      type="text"
      readonly
      [placeholder]="'mobile-placeholder-materials' | translate"
      *ngIf="parentFormGroup.value.materials.length === 0">
    </ion-input>
    <ion-icon name="materials" color="primary" slot="start"></ion-icon>
    <span class="assets-container" *ngIf="parentFormGroup.value.materials.length > 0">
        {{ parentFormGroup.value.materials | listToLabelList | join }}
    </span>
  </ion-item>
</div>

<div *ngIf="deviceIsMobile && isMobileEventPreviewComponent" class="custom-event-preview-mobile">
  <ion-grid *ngIf="materials.length > 0">
    <ion-row>
      <ion-col>
        <header class="label">{{customField.title}}</header>
      </ion-col>
    </ion-row>
    <ion-row *ngFor="let material of materials" class="people-row">
      <ion-col>
        <div class="people-pill-labour">
          <ion-grid>
            <ion-row>
              <ion-col>
                <p>{{ material.name }}</p>
                <p class="supplier"> {{ material.supplier }} </p>
              </ion-col>
              <ion-col class="text-right" size="auto">
                <span>
                  {{ 'plugins.select.qty' | translate }} {{ material.amount }}
                </span>
              </ion-col>
              <ion-col class="text-right" size="auto">
                <span *ngIf="material.quantityUnit">{{ this.quantitiesUnits[material.quantityUnit] }}</span>
              </ion-col>
            </ion-row>
          </ion-grid>
        </div>
      </ion-col>
    </ion-row>
  </ion-grid>
</div>

