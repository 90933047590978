export class UserSettings {
  public layout: 'fixed' | 'full' = 'fixed';
  public sidebarCollapsed = false;
  public onBoardingHasBeenShown = false;
  public lastVisitedSpace = null;
  public lastVisitedSite = null;
  public lastVisitedApp: userAppType = userAppType.DIARY;
}

export enum userAppType {
  DIARY = 'diary',
  TASK = 'tasks',
}
