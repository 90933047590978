<!-- WEBAPP TEMPLATE -->
<div *ngIf="!deviceService.isMobile">

  <div class="row top-row m-0 w-100">
    <div class="flex-row ml-0" *ngIf="status.length !== 0">
      <div *ngFor="let item of status" >
        <div class="status pr-3" *ngIf="item.id !== EventStatus.PENDING">
          <ion-icon [color]="item.color" name="bubble" class="pr-1"></ion-icon>
          <ion-label>
            {{ item.name | translate }}
          </ion-label>
        </div>
      </div>
    </div>

    <!-- SEARCHBAR -->
    <div class="col-4 d-flex flex-row w-50 m-0 p-0">
      <ion-searchbar #searchInput class="pl-0 no-shadow"
        placeholder="{{'placeholder.search' | translate}}" animated (ionInput)="updateSearchedEvents()" ></ion-searchbar>
    </div>
    <!-- END OF SEARCH BAR-->
    <div class="top-btn-grp">
      <button *ngIf="canCreateEvent" type="button" class="btn btn-primary m-0 ml-1" (click)="createEvent()">
        +&nbsp;{{'events.action.create' | translate}}
      </button>
      <div *ngIf="userRightsService.hasRight(userRightsService.USER_RIGHTS.site.event.export)" ngbDropdown class="d-inline-block m-0 ml-2">
        <button class="btn white-background-color text-dark" id="exportReportDropdown" ngbDropdownToggle> {{'btn.export' | translate}}</button>
        <div ngbDropdownMenu aria-labelledby="exportReportDropdown">
          <!-- PDF -->
          <button
            ngbDropdownItem
            (click)="openPdfReportModal()"
            matTooltipPosition="above"
            [matTooltip]="(isOnline | async) ? '' : ('events.export.offline' | translate)"
            [disabled]="!(isOnline | async)">
            <span>
              {{ 'events.export.pdf.label' | translate }}
            </span>
          </button>
          <!-- EXCEL -->
          <button
            ngbDropdownItem
            (click)="getXlsReport()"
            matTooltipPosition="above"
            [matTooltip]="(isOnline | async) ? '' : ('events.export.offline' | translate)"
            [disabled]="!(isOnline | async)">
            <span>
              {{ 'events.export.excel.label' | translate }}
            </span>
          </button>
          <!-- SUMMARY PDF -->
          <button
            ngbDropdownItem
            (click)="openPdfSummaryModal()"
            matTooltipPosition="above"
            [matTooltip]="(isOnline | async) ? '' : ('events.export.offline' | translate)"
            [disabled]="!(isOnline | async)" >
            <span>
              {{ 'events.export.pdf.summary.label' | translate }}
            </span>
          </button>
          <!-- CSV -->
          <button
            ngbDropdownItem
            (click)="getCsvReport()"
            matTooltipPosition="above"
            [matTooltip]="(isOnline | async) ? '' : ('events.export.offline' | translate)"
            [disabled]="!(isOnline | async)">
            <span>
              {{ 'events.export.csv.label' | translate }}
            </span>
          </button>
        </div>
      </div>
    </div>

  </div>

  <!-- FILTERS -->
  <div *ngIf="eventsFilters" class="flex-row filters">
    <div class="filters">
      <!-- DATE FROM -->
      <div class="filter" (click)="openDatePicker('startDatetime')">
        <div class="input-group">
          <input id="pickerDateFrom" class="form-control"
              placeholder="{{ 'filter.from' | translate }}" [ngModel]="eventsFilters.dateFrom | date : localizedFormat"
                 [ngClass]="{'is-invalid': invalidDatesInFilter()}">
          <div class="input-group-append">
            <button class="btn btn-outline-primary override-outline" type="button">
              <em></em>
            </button>
          </div>
        </div>
      </div>
      <!-- DATE TO -->
      <div class="filter" (click)="openDatePicker('endDatetime')">
        <div class="input-group">
          <input id="pickerDateTo" class="form-control"
              placeholder="{{ 'filter.to' | translate }}" [ngModel]="eventsFilters.dateTo  | date : localizedFormat"
                 [ngClass]="{'is-invalid': invalidDatesInFilter()}">
          <div class="input-group-append">
            <button class="btn btn-outline-primary override-outline" type="button">
              <em></em>
            </button>
          </div>
        </div>
      </div>
      <!-- CONTRACTOR -->
      <div class="filter">
        <angular2-multiselect [data]="contractors" [settings]="multiselectContractorSettings" (onOpen)="swapSearchBar()"
          id="selectedContractor" [(ngModel)]="selectedContractorItems">
        </angular2-multiselect>
      </div>
      <!-- TAG -->
      <div class="filter">
        <angular2-multiselect [data]="tags" [settings]="multiselectTagSettings" (onOpen)="swapSearchBar()"
          id="selectedTag" [(ngModel)]="selectedTagItems">
        </angular2-multiselect>
      </div>
      <!-- LOCALISATION -->
      <div class="filter">
        <angular2-multiselect [data]="locations" [settings]="multiselectLocationSettings" (onOpen)="swapSearchBar()"
          id="selectedLocation" [(ngModel)]="selectedLocationItem">
        </angular2-multiselect>
      </div>
      <!-- CREATOR -->
      <div class="filter">
        <angular2-multiselect [data]="creators" [settings]="multiselectCreatorSettings" (onOpen)="swapSearchBar()"
          id="selectedCreator" [(ngModel)]="selectedCreatorItems">
        </angular2-multiselect>
      </div>
      <!-- STATUS -->
      <div class="filter">
        <angular2-multiselect [data]="status" [settings]="multiselectStatusSettings" (onOpen)="swapSearchBar()"
          id="selectedStatus" [(ngModel)]="selectedStatusItems">
        </angular2-multiselect>
      </div>
      <!-- TASK -->
      <div class="filter">
        <angular2-multiselect [data]="tasks" [settings]="multiselectTaskSettings" (onOpen)="swapSearchBar()"
          id="selectedTask" [(ngModel)]="selectedTaskItems">
        </angular2-multiselect>
      </div>
      <!-- APPLY FILTER BUTTON -->
      <div class="filter">
        <button class="btn apply-filter-btn text-dark" (click) = "applyAllFilters()" >
          {{'btn.apply_filters' | translate}}
        </button>
      </div>
      <!-- CLEAR FILTER BUTTON -->
      <div class="filter">
        <button class="btn btn-outline-primary clear-all-btn" (click) = "clearAllFilters()" >
         {{'btn.clear_all' | translate}}
        </button>
      </div>
      <!-- REFRESH BUTTON  -->
      <div class="ml-auto">
        <div class="force-refresh-button">
          <button class="btn btn-link" (click)="forceRefresh()">
            <ion-icon name="refresh" mode="ios"></ion-icon>
            <span>{{'label.synchronization.short' | translate}}</span>
          </button>
        </div>
      </div>
    </div>
    <small class="text-danger" *ngIf="invalidDatesInFilter()">{{'form.error.event-and-task.negativeDuration' | translate }}</small>
  </div>
  <!-- END FILTERS -->

  <!-- FAULTY EVENTS INDICATOR CARD -->
  <ion-card *ngIf="hasFaultyItems()" class="danger">
    <ion-card-header (click)="routeToFaultyItems()">
      <ion-card-subtitle *ngIf = "numberOfFaultyItems === 1">{{numberOfFaultyItems}} {{'faulty.items.one.sync.error.title' | translate }}</ion-card-subtitle>
      <ion-card-subtitle *ngIf = "numberOfFaultyItems > 1">{{numberOfFaultyItems}} {{'faulty.items.sync.error.title' | translate }}</ion-card-subtitle>
      <i class="fa-angle-right fas"></i>
    </ion-card-header>
  </ion-card>

  <!-- DATATABLE -->
  <div class="row">
    <div class="col-12 col-sm-12 col-md-12 mt-1 btn-fixed-space">
      <div *ngIf="dataLoading" class="custom-paginator">
        <div class="datatable-spinner">
          <span>{{'loading_text.loading' | translate}}</span>
          <mat-progress-bar mode="indeterminate"></mat-progress-bar>
        </div>
      </div>
      <ngx-datatable #table class="bootstrap" [ngClass]="{ 'not-scrollable': !canScrollDatatable }"
        [limit]="itemPerPage" [columnMode]="'force'" [messages]="{emptyMessage: 'events.list.no_event' | translate}"
        [rows]="filteredEvents" [scrollbarH]="true" [headerHeight]="40" [footerHeight]="60" [rowHeight]="'auto'"
        [sorts]="[{prop: 'start_dateTime', dir: 'desc'}]" (activate)="onActivateRow($event)">

        <ngx-datatable-column prop="status" name="{{'events.list.state' | translate}}" [minWidth]="60" [maxWidth]="60"
          cellClass="cell-status">
          <ng-template ngx-datatable-cell-template let-value="value">
            <ion-icon [color]="getStatus(value).color" name="bubble" class="pl-1"
              matTooltip="{{ getStatus(value).name | translate}}"></ion-icon>
          </ng-template>
        </ngx-datatable-column>

        <ngx-datatable-column prop="startDatetime" name="{{'events.list.occurred_on' | translate}}" [minWidth]="80">
          <ng-template ngx-datatable-cell-template let-value="value">
            <span class="font-weight-normal fs-1">{{ value | date:'mediumDate' }}</span>
            <br />
            <span class="font-weight-normal fs-1">{{ value | date:'shortTime' }}</span>
          </ng-template>
        </ngx-datatable-column>

        <ngx-datatable-column prop="title" name="{{'events.list.title' | translate}}" [minWidth]="200" [maxWidth]="500"
          [width]="300" [comparator]="customComparString">
          <ng-template ngx-datatable-cell-template let-rowIndex="rowIndex" let-event="row" let-value="value">
            <ion-grid fixed>
              <ion-row no-padding>
                <ion-col no-padding class="big" text-start size="12">
                  <span class="font-weight-normal fs-1" *ngIf="event.id !== ''">
                    {{ value ? event.title : 'label.no_title' | translate }}
                  </span>
                </ion-col>
              </ion-row>
              <ion-row class="mt-1" no-padding>
                <ion-col no-padding size="12">
                  <ion-badge *ngFor="let tag of event.tags" class="low-opacity tag-badge" margin-end color="primary">{{ tag.name }}</ion-badge>
                </ion-col>
              </ion-row>
            </ion-grid>
          </ng-template>
        </ngx-datatable-column>

        <ngx-datatable-column prop="contractors" name="{{'label.contractors' | translate}}" [minWidth]="150">
          <ng-template ngx-datatable-cell-template let-contractors="value">
            <span *ngFor="let contractor of contractors; let i = index" class="font-weight-normal fs-1">
              {{contractor.name}}
              <span *ngIf="i < contractors.length - 1">, </span>
            </span>
          </ng-template>
        </ngx-datatable-column>

        <ngx-datatable-column prop="locationObject" name="{{'events.list.location' | translate}}" [minWidth]="150"
          [comparator]="customComparString">
          <ng-template ngx-datatable-cell-template let-value="value">
            <span class="font-weight-normal fs-1">{{value.name}}</span>
          </ng-template>
        </ngx-datatable-column>

        <ngx-datatable-column [sortable]="false" prop="attachments" name="{{'events.list.images' | translate}}"
          [minWidth]="60">
          <ng-template ngx-datatable-cell-template let-event="row" let-value="value">
            <div class="gallery-cell">
              <ion-item lines="none" *ngIf="value?.length > 0">
                <img
                  [src]="getThumbnailUrl(value[0], event.id) | secureImageSrc | async"
                  class="thumbnail small mr-2">
                <ion-label color="medium" *ngIf="value?.length > 1">+{{value.length - 1}}</ion-label>
              </ion-item>
            </div>
          </ng-template>
        </ngx-datatable-column>

        <ngx-datatable-column prop="createdBy" name="{{'events.list.created_by' | translate}}" [minWidth]="150"
          [comparator]="customComparAuthor">
          <ng-template ngx-datatable-cell-template let-value="value">
            <span
              class="font-weight-normal fs-1">{{ value.id ? value.name : 'users.error.not_found' | translate}}</span>
          </ng-template>
        </ngx-datatable-column>

        <ngx-datatable-footer>
          <ng-template ngx-datatable-footer-template let-rowCount="rowCount" let-pageSize="pageSize"
            let-selectedCount="selectedCount" let-curPage="curPage" let-offset="offset" let-isVisible="isVisible">
            <app-datatable-footer class="w-100 h-25" [dataLoading]="false"
              (changeNbItemEvent)="receiveItemPerPage($event)" [totalEntries]="rowCount"
              [table]="table" [curPage]="curPage" [pageSize]="pageSize" [rowCount]="rowCount"></app-datatable-footer>
          </ng-template>
        </ngx-datatable-footer>
      </ngx-datatable>
    </div>
  </div>
  <!-- END DATATABLE -->
</div>
<!-- END WEBAPP TEMPLATE -->

<!-- APP TEMPLATE -->
<div *ngIf="deviceService.isMobile" class="whitesmoke-background" [ngClass] = "{'tablet-landscape': isTabletInLandscape }">

  <!-- EVENTS LIST -->
  <ion-grid class="pb-5 ion-no-padding ion-no-margin">

    <div class="d-flex flex-row flex-nowrap justify-content-around align-items-center">
      <div class="searchbar col-auto d-flex flex-row w-60 ion-no-padding ion-text-start">
        <!-- SEARCHBAR -->
        <ion-searchbar #searchInput class="no-shadow"
          placeholder="{{'btn.search' | translate}}" animated debounce="300" (ionChange)="onMobileSearchInputChange($event.target.value)"></ion-searchbar>
        <!-- END OF SEARCH BAR-->
      </div>
      <div class="icon-feature d-flex justify-content-center align-items-center ion-no-padding ion-text-start">
        <!-- FILTERS BUTTON -->
        <ion-icon name="options" class="function-events" (click)="openFiltersModal()">
          </ion-icon>
        <!-- END OF FILTERS BUTTON -->
      </div>
      <button id="export" (click)="openExportModal()" class="btn btn-link icon-feature d-flex align-items-center ion-no-padding ion-text-start" *ngIf="userRightsService.hasRight(userRightsService.USER_RIGHTS.site.event.export)">
        <!-- EXPORT BUTTON -->
        <ion-icon name="custom-share" id="export-icon" class="function-events">
        </ion-icon>
        <span>{{ 'btn.export' | translate }}</span>
        <!-- END OF EXPORT BUTTON -->
      </button>
    </div>

    <!-- FAULTY ITEMS INDICATOR CARD -->
    <ion-card *ngIf="hasFaultyItems()" class="danger">
      <ion-card-header (click)="routeToFaultyItemsMobile()">
        <ion-card-subtitle *ngIf = "numberOfFaultyItems === 1">{{numberOfFaultyItems}} {{'faulty.items.one.sync.error.title' | translate }}</ion-card-subtitle>
        <ion-card-subtitle *ngIf = "numberOfFaultyItems > 1">{{numberOfFaultyItems}} {{'faulty.items.sync.error.title' | translate }}</ion-card-subtitle>
        <i class="fa-angle-right fas"></i>
      </ion-card-header>
    </ion-card>

    <!--  -->
    <ion-card *ngIf="showUpdateBanner" class="update-banner">
      <ion-card-header>
        <ion-card-subtitle>{{ 'app.update.available' | translate }}</ion-card-subtitle>
        <ion-button (click)="closeAppUpdateBanner()" class="cancel-button">{{ 'app.update.cancel.button' | translate }}</ion-button>
        <ion-button (click)="goToAppStore()">{{ 'app.update.update.button' | translate }}</ion-button>
      </ion-card-header>
    </ion-card>

    <!-- LIMITED SEARCH RESULTS BANNER -->
    <ion-card *ngIf = "showLimitedSearchBanner" class="limited-search-banner">
      <ion-card-header>
        <ion-card-subtitle>{{'filters.limited_search.results' | translate }}</ion-card-subtitle>
      </ion-card-header>
    </ion-card>

    <!-- ion card is being made to use only the ios platform style for all devices as the android style
         is bugged and doesn't display correctly all the time.  -->
    <ion-card mode="ios" *ngIf="(totalEventsLength === 0 && shownEventsLength === 0) && !dataLoading">
      <ion-card-content>
        {{'events.list.no_events_created' | translate}}
      </ion-card-content>
    </ion-card>

    <ion-grid class="progress-grid" *ngIf="(isOnline | async) && showUploadInProgress">
      <ion-row class="ion-justify-content-between">
        <ion-col>
          <p class="progress-description"><ion-spinner></ion-spinner>{{ 'label.uploading_events' | translate }}</p>
        </ion-col>
        <ion-col size="auto">
          <p class="progress-description">{{ getUploadCompletionPercent() }}</p>
        </ion-col>
      </ion-row>
      <ion-row>
        <ion-col>
          <ion-progress-bar [value]="getUploadCompletion()"></ion-progress-bar>
        </ion-col>
      </ion-row>
    </ion-grid>

    <ion-list class="whitesmoke-background" *ngIf="totalEventsLength > 0 || shownEventsLength > 0">
      <!-- LIST FOR TODAY AND YESTERDAY -->
      <ion-item-group *ngFor="let day of (lastTwoDays || [])">
        <!-- ITEM DIVIDERS -->
        <ion-item-divider>
          <div class="today-title refresh-button" *ngIf="dateManipulationService.isToday(day)">
            <ion-label color="primary" *ngIf="todaysEvents.length > 0">
              <div>
                <b>{{ 'today' | translate | uppercase}}</b>
              </div>
            </ion-label>
            <div class="refresh-button">
              <button class="btn btn-link d-flex force-refresh-button-mobile" (click)="forceRefresh()">
                <ion-icon name="refresh-circle-outline"></ion-icon>
                <small class="pl-1 d-flex">{{ 'label.refresh' | translate }}</small>
              </button>
            </div>
          </div>
          <ion-label color="primary" *ngIf="dateManipulationService.isYesterday(day) && yesterdaysEvents.length > 0">
            <b>{{ 'yesterday' | translate | uppercase}}</b>
          </ion-label>
        </ion-item-divider>
        <!-- END OF ITEM DIVIDERS -->

        <!-- EVENTS FOR TODAY AND YESTERDAY -->
        <ng-container *ngIf="events | filterEventByDate: day as filteredEvents">
          <ion-item-sliding *ngFor="let event of filteredEvents; let i = index" #item>
            <ion-card mode="ios" class="ion-no-padding ion-no-margin">
              <ion-card-content class="ion-no-padding">
                <ion-item lines="none"  class="list-item"
                  [ngClass]="{'separator': i < (events.length - 1) }"
                  routerLink="../{{event.id}}">
                  <ion-grid fixed class="ion-no-padding ion-no-margin">
                    <ion-row class="row-padding-top ion-no-padding">
                      <ion-col class="small dot ion-no-padding ion-text-start"  size="1">
                        <span  [ngClass]='event.status | eventStatusToIonColor'></span>
                      </ion-col>
                      <ion-col class="big id-container" size="auto">
                        <p *ngIf="event?.eventNumber" class='diary-id'>{{ eventNumberPrefix }}{{ event.eventNumber }}</p>
                        <p *ngIf="!event?.eventNumber" class="info-container">
                          <ion-icon
                            name="information-circle-outline"
                            class="info-icon"
                            matTooltip="{{'events.list.diary_id.not_synced' | translate}}"
                            [matTooltipPosition]="'below'"
                            (click)="showTooltip($event, tooltip)"
                            #tooltip="matTooltip">
                          </ion-icon>
                        </p>
                      </ion-col>
                      <ion-col class="big ion-no-padding ion-text-start" size="5">
                        <p>{{event.title}}</p>
                      </ion-col>
                      <ion-col class="big-font-weight-normal ion-no-padding ion-text-end" size="3" *ngIf="!userRightsService.hasRight([UserRole.siteMember])">
                        <p>{{ event.createdBy.name }}</p>
                      </ion-col>
                    </ion-row>

                    <ion-row class="row-padding-top">
                      <ion-col class="big-font-weight-normal ion-no-padding ion-text-start" size="6">
                          <p>{{event.startDatetime | date:'dd/MM/yy'}} {{'hyphen.symbol' | translate}} {{event.startDatetime | date:'HH:mm'}}</p>
                      </ion-col>
                      <ion-col class="big ion-no-padding ion-text-end" size="6" *ngIf="event.isEventSynced === false">
                        <ion-note class="sync-pending" color="warning">{{'events.list.sync.pending' | translate}}</ion-note>
                      </ion-col>
                    </ion-row>

                    <ion-row class="mt-1" class="row-padding-top ion-no-padding" >
                      <ion-col size="8" class="tag-column ion-no-padding">
                        <ion-badge *ngFor="let tag of event.tags" class="tag-badge ion-margin-end" color="primary">{{ tag.name }}</ion-badge>
                      </ion-col>

                      <!-- Icons summarizing content of event-->
                      <ion-col size="4" class="icon-detail summary-icons ion-no-padding ion-text-end">
                        <!-- contractors -->
                        <ion-icon name="contractor" *ngIf="event.contractors.length > 0"></ion-icon>
                        <ion-note class="pl-1" *ngIf="event.contractors.length > 0">{{ event.contractors.length }}</ion-note>
                        <!-- pictures -->
                        <ion-icon name="custom-camera" *ngIf="event.attachments.length > 0"></ion-icon>
                        <ion-note class="pl-1" *ngIf="event.attachments.length > 0">{{ event.attachments.length }}</ion-note>
                      </ion-col>
                    </ion-row>
                  </ion-grid>
                </ion-item>

                <!-- SWIPE OPTIONS-->
                <ion-item-options *ngIf="canApprove(event)" side="start">
                  <ion-item-option (click)="approveEvent(event, item)" color="success">
                    <ion-icon mode="md" class="pr-1" name="checkmark-circle"></ion-icon>
                    {{'events.detail.btn.approve' | translate}}
                  </ion-item-option>
                  <ion-item-option *ngIf="canReject(event)" (click)="showRejectAlert(event, item)" color="warning">
                    <ion-icon mode="md" class="pr-1" name="close-circle"></ion-icon>
                    {{'events.detail.btn.reject' | translate}}
                  </ion-item-option>
                </ion-item-options>
                <ion-item-options *ngIf="canEdit(event)" side="end">
                  <ion-item-option (click)="showDeleteAlert(event, item)" color="danger">
                    <ion-icon mode="md" class="pr-1" name="custom-delete"></ion-icon>
                    {{'events.detail.btn.delete' | translate}}
                  </ion-item-option>
                </ion-item-options>
                <!-- END OF SWIPE OPTIONS -->

              </ion-card-content>
            </ion-card>
          </ion-item-sliding>
          <!-- END OF EVENTS FOR TODAY AND YESTERDAY  -->
        </ng-container>
      </ion-item-group>
      <!-- END OF LIST FOR TODAY AND YESTERDAY -->

      <!-- LAST WEEKS EVENTS -->
      <ion-item-group *ngIf="lastWeeksEvents.length > 0" >
        <ion-item-divider>
          <ion-label color="primary" >
            <b>{{'last_week'| translate | uppercase}}</b>
          </ion-label>
        </ion-item-divider>
        <ng-container *ngIf="lastWeeksEvents as filteredEvents">
          <ion-item-sliding *ngFor="let event of filteredEvents; let i = index" #item>
            <ion-card mode="ios" class="ion-no-padding ion-no-margin">
              <ion-card-content class="ion-no-padding">
                <ion-item lines="none"  class="list-item"
                  [ngClass]="{'separator': i < (lastWeeksEvents.length - 1) }"
                  routerLink="../{{event.id}}">
                  <ion-grid fixed class="ion-no-padding ion-no-margin">
                    <ion-row class="row-padding-top ion-no-padding">
                      <ion-col class="small dot ion-no-padding ion-text-start" size="1">
                        <span [ngClass]='event.status | eventStatusToIonColor'></span>
                      </ion-col>
                      <ion-col class="big id-container" size="auto">
                        <p *ngIf="event?.eventNumber" class='diary-id'>{{ eventNumberPrefix }}{{ event.eventNumber }}</p>
                        <p *ngIf="!event?.eventNumber" class="info-container">
                          <ion-icon
                            name="information-circle-outline"
                            class="info-icon"
                            matTooltip="{{'events.list.diary_id.not_synced' | translate}}"
                            [matTooltipPosition]="'below'"
                            (click)="showTooltip($event, tooltip)"
                            #tooltip="matTooltip">
                          </ion-icon>
                        </p>
                      </ion-col>
                      <ion-col class="big ion-no-padding" text-start size="5">
                        <p>
                          {{event.title}}
                        </p>
                      </ion-col>
                      <ion-col size="3" class="big-font-weight-normal ion-no-padding ion-text-end" *ngIf="!userRightsService.hasRight([UserRole.siteMember])">
                        <p>{{ event.createdBy.name }}</p>
                      </ion-col>
                    </ion-row>
                    <ion-row class="row-padding-top">
                      <ion-col class="big-font-weight-normal ion-no-padding" text-start size="6">
                          <p>{{event.startDatetime | date:'dd/MM/yy'}} {{'hyphen.symbol' | translate}} {{event.startDatetime | date:'HH:mm'}}</p>
                      </ion-col>
                      <ion-col class="big ion-no-padding" text-end size="6" *ngIf="event.isEventSynced === false">
                        <ion-note class="sync-pending" color="warning">{{'events.list.sync.pending' | translate}}</ion-note>
                      </ion-col>
                    </ion-row>
                    <!-- tags -->
                    <ion-row class="mt-1 row-padding-top ion-no-padding">
                      <ion-col size="8" class="tag-column ion-no-padding">
                        <ion-badge *ngFor="let tag of event.tags" class="tag-badge ion-margin-end"  color="primary">{{ tag.name }}</ion-badge>
                      </ion-col>
                      <!-- Icons summarizing content of event -->
                      <ion-col size="4" class="icon-detail summary-icons ion-no-padding ion-text-end">
                        <!-- contractors -->
                        <ion-icon name="contractor" *ngIf="event.contractors.length > 0"></ion-icon>
                        <ion-note class="pl-1" *ngIf="event.contractors.length > 0">{{ event.contractors.length }}</ion-note>
                        <!-- pictures -->
                        <ion-icon name="custom-camera" *ngIf="event.attachments.length > 0"></ion-icon>
                        <ion-note class="pl-1" *ngIf="event.attachments.length > 0">{{ event.attachments.length }}</ion-note>
                      </ion-col>
                    </ion-row>
                  </ion-grid>
                </ion-item>
                <!-- SWIPE OPTIONS-->
                <ion-item-options *ngIf="canApprove(event)" side="start">
                  <ion-item-option (click)="approveEvent(event, item)" color="success">
                    <ion-icon mode="md" class="pr-1" name="checkmark-circle"></ion-icon>
                    {{'events.detail.btn.approve' | translate}}
                  </ion-item-option>
                  <ion-item-option *ngIf="canReject(event)" (click)="showRejectAlert(event, item)" color="warning">
                    <ion-icon mode="md" class="pr-1" name="close-circle"></ion-icon>
                    {{'events.detail.btn.reject' | translate}}
                  </ion-item-option>
                </ion-item-options>
                <ion-item-options *ngIf="canEdit(event)" side="end">
                  <ion-item-option (click)="showDeleteAlert(event, item, true)" color="danger">
                    <ion-icon mode="md" class="pr-1" name="custom-delete"></ion-icon>
                    {{'events.detail.btn.delete' | translate}}
                  </ion-item-option>
                </ion-item-options>
                <!-- END OF SWIPE OPTIONS -->
              </ion-card-content>
            </ion-card>
          </ion-item-sliding>
        </ng-container>
      </ion-item-group>
      <!-- END OF LAST WEEKS EVENTS -->

      <!-- EARLIER EVENTS LIST -->
      <ion-item-group *ngIf="hasFetchEarlierEvents">
        <ion-item-divider *ngIf="earlierEvents.length > 0">
          <ion-label color="primary" >
            <b>{{'earlier' | translate | uppercase}}</b>
          </ion-label>
        </ion-item-divider>
        <ng-container *ngIf="earlierEvents as filteredEvents">
          <ion-item-sliding *ngFor="let event of filteredEvents; let i = index" #item>
            <ion-card mode="ios" class="ion-no-padding ion-no-margin">
              <ion-card-content class="ion-no-padding">
                <ion-item lines="none"  class="list-item"
                  [ngClass]="{'separator': i < (earlierEvents.length - 1) }"
                  routerLink="../{{event.id}}">
                  <ion-grid fixed class="ion-no-padding ion-no-margin">
                    <ion-row class="row-padding-top ion-no-padding">
                      <ion-col class="small dot ion-no-padding ion-text-start" size="1">
                        <span [ngClass]='event.status | eventStatusToIonColor'></span>
                      </ion-col>
                      <ion-col class="big id-container" size="auto">
                        <p *ngIf="event?.eventNumber" class='diary-id'>{{ eventNumberPrefix }}{{ event.eventNumber }}</p>
                        <p *ngIf="!event?.eventNumber" class="info-container">
                          <ion-icon
                            name="information-circle-outline"
                            class="info-icon"
                            matTooltip="{{'events.list.diary_id.not_synced' | translate}}"
                            [matTooltipPosition]="'below'"
                            (click)="showTooltip($event, tooltip)"
                            #tooltip="matTooltip">
                          </ion-icon>
                        </p>
                      </ion-col>
                      <ion-col class="big ion-no-padding ion-text-start" size="5">
                        <p>
                          {{event.title}}
                        </p>
                      </ion-col>
                      <ion-col size="3" class="big-font-weight-normal ion-no-padding ion-text-end" *ngIf="!userRightsService.hasRight([UserRole.siteMember])">
                        <p>{{ event.createdBy.name }}</p>
                      </ion-col>
                    </ion-row>
                    <ion-row class="row-padding-top">
                      <ion-col class="big-font-weight-normal ion-no-padding ion-text-start" size="6">
                          <p>{{event.startDatetime | date:'dd/MM/yy'}} {{'hyphen.symbol' | translate}} {{event.startDatetime | date:'HH:mm'}}</p>
                      </ion-col>
                      <ion-col class="big ion-no-padding ion-text-end" size="6" *ngIf="event.isEventSynced === false">
                        <ion-note class="sync-pending" color="warning">{{'events.list.sync.pending' | translate}}</ion-note>
                      </ion-col>
                    </ion-row>
                    <!-- tags -->
                    <ion-row class="mt-1 row-padding-top ion-no-padding">
                      <ion-col size="8" class="tag-column ion-no-padding">
                        <ion-badge *ngFor="let tag of event.tags" class="tag-badge ion-margin-end" color="primary">{{ tag.name }}</ion-badge>
                      </ion-col>
                      <!-- Icons summarizing content of event -->
                      <ion-col size="4" class="icon-detail summary-icons ion-no-padding ion-text-end">
                        <!-- contractors -->
                        <ion-icon name="contractor" *ngIf="event.contractors.length > 0"></ion-icon>
                        <ion-note class="pl-1" *ngIf="event.contractors.length > 0">{{ event.contractors.length }}</ion-note>
                        <!-- pictures -->
                        <ion-icon name="custom-camera" *ngIf="event.attachments.length > 0"></ion-icon>
                        <ion-note class="pl-1" *ngIf="event.attachments.length > 0">{{ event.attachments.length }}</ion-note>
                      </ion-col>
                    </ion-row>
                  </ion-grid>
                </ion-item>
                <!-- SWIPE OPTIONS-->
                <ion-item-options *ngIf="canApprove(event)" side="start">
                  <ion-item-option (click)="approveEvent(event, item)" color="success">
                    <ion-icon mode="md" class="pr-1" name="checkmark-circle"></ion-icon>
                    {{'events.detail.btn.approve' | translate}}
                  </ion-item-option>
                  <ion-item-option *ngIf="canReject(event)" (click)="showRejectAlert(event, item)" color="warning">
                    <ion-icon mode="md" class="pr-1" name="close-circle"></ion-icon>
                    {{'events.detail.btn.reject' | translate}}
                  </ion-item-option>
                </ion-item-options>
                <ion-item-options *ngIf="canEdit(event)" side="end">
                  <ion-item-option (click)="showDeleteAlert(event, item, true)" color="danger">
                    <ion-icon mode="md" class="pr-1" name="custom-delete"></ion-icon>
                    {{'events.detail.btn.delete' | translate}}
                  </ion-item-option>
                </ion-item-options>
                <!-- END OF SWIPE OPTIONS -->
              </ion-card-content>
            </ion-card>
          </ion-item-sliding>
        </ng-container>
      </ion-item-group>
    </ion-list>
    <!-- END OF EARLIER EVENTS LIST -->
    <!-- SWIPABLE PANEL TO LOAD MORE EVENTS -->
    <div (panup)="swipeUp($event)" (panend)="loadMoreEvents()" *ngIf="!areAllEventsShown" class="lower-mobile-buttons">
      <ion-label *ngIf="!dataLoading">
        <ion-icon name="chevron-up" color="primary" class="icon-x2 swipe-icon" [ngClass]="{ 'rotated': swipeIconDown}">
        </ion-icon>
      </ion-label>
      <ion-label *ngIf="!dataLoading" (click)="loadMoreEvents()">
        <a class="load-text">{{'load_more' | translate}}</a>
      </ion-label>

      <ion-label *ngIf="dataLoading">
        <ion-spinner color="medium" name="lines-small" color="primary" class="icon-x2"></ion-spinner>
      </ion-label>
      <ion-label *ngIf="dataLoading">
        <a class="load-text">{{'loading_text.loading' | translate}}</a>
      </ion-label>
    </div>
    <!-- END OF SWIPABLE PANEL -->

    <!-- LIMITED RESULTS BANNER -->
    <ion-card *ngIf="areAllEventsShown && ((totalEventsLength >= 50) && !dataLoading) && !showLimitedSearchBanner" class="limited-search-banner">
      <ion-card-header>
        <ion-card-subtitle>{{'filters.limited_search.results' | translate }}</ion-card-subtitle>
      </ion-card-header>
    </ion-card>
    <!-- END LIMITED RESULTS BANNER -->
  </ion-grid>
</div>
<!-- END APP TEMPLATE -->
