export class GlobalConstants {
  public static siteProductivityHelpCentreLink = 'https://intercom.help/scriptgo/en/collections/9523657-site-productivity';
  public static localNotificationIconLink = 'res://drawable/ic_launcher_round';
  public static supportedCustomFormVersion = 2;
  public static maxLengthAllowedInShortTextField = 256;
  public static maxLengthAllowedInLongTextField = 4000;
  public static customFieldOptionsMaxLength = 60;
  public static numericalScaleLowerEndValueRange = [0, 1]
  public static numericalScaleHigherEndValueRange = [2, 3, 4, 5, 6, 7, 8, 9, 10]
  public static numericalScaleDefaultLowerEndValue = 0
  public static numericalScaleDefaultHigherEndValue = 10
  public static diaryEventNumberPrefix = 'D-';
  public static maximumSiteDiarySeats: number = 100;
  public static maximumSiteTaskSeats: number = 100;
  public static maximumUpgradableSeatCount: number = 100;
  public static siteDiaryLogoURL: string = '/assets/img/logo/casque_sitediary_purple.svg';
  public static siteTaskLogoURL: string = '/assets/img/logo/site_task_logo.svg';
  public static intercomInviteUserLink = 'https://intercom.help/scriptgo/en/articles/8898648-site-diary-site-task-how-to-invite-the-team';
  public static termsAndConditionsLink = 'https://sitediary.com/terms-conditions/';
  public static audCurrencySymbol = 'A$';
  public static usdCurrencySymbol = '$';
  public static cadCurrencySymbol = 'C$';
  public static gbpCurrencySymbol = '£';
  public static eurCurrencySymbol = '€';
  public static taxRate = 20;
  public static WEEK_MS = 518400000;
}
