import { Injectable } from '@angular/core';
import { Asset, AssetCategory } from '../models/asset';
import { DatabaseService } from './shared/database.service';
import { EventAsset } from '../models/event-asset';
import { TaskAsset } from '../models/task-asset';
import { Task } from '@models/task';
import { SharedService } from './shared/shared.service';
import { AbstractModelService } from '@services/abstract-model-service';
import { BehaviorSubject, Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { linkedToTask } from '@operators/linked-to-task.operator';
import { EventService } from './event.service';
import { TaskService } from './task.service';
import { first } from 'rxjs/internal/operators/first';
import { SharedDataService } from './shared-data.service';
import { DeviceService } from './device.service';

/**
 * Asset service. Use for interact with webservice for get, add, delete or update {@link Asset} objects
 */
@Injectable()
export class AssetService extends AbstractModelService<Asset> {
  protected type = 'Asset';

  public readonly labours = this.items.pipe(map(assets => this.filterAssets(assets, AssetCategory.LABOURS)));
  public readonly equipments = this.items.pipe(map(assets => this.filterAssets(assets, AssetCategory.EQUIPMENTS)));
  public readonly materials = this.items.pipe(map(assets => this.filterAssets(assets, AssetCategory.MATERIALS)));
  public readonly siteLabours = this.labours.pipe(map(assets => assets.filter(asset => asset.siteId === this.sharedService.currentSiteId)));
  public readonly siteEquipments = this.equipments.pipe(map(assets => assets.filter(asset => asset.siteId === this.sharedService.currentSiteId)));
  public readonly siteMaterials = this.materials.pipe(map(assets => assets.filter(asset => asset.siteId === this.sharedService.currentSiteId )));
  public readonly spaceLabours = this.labours.pipe(map(assets => assets.filter(asset => asset.siteId === 'null')));
  public readonly spaceEquipments = this.equipments.pipe(map(assets => assets.filter(asset => asset.siteId === 'null')));
  public readonly spaceMaterials = this.materials.pipe(map(assets => assets.filter(asset => asset.siteId === 'null')));

  private assetsSearchInput$ = new BehaviorSubject<string>('');
  public readonly watchAssetsSerachInput: Observable<string>;

  private filterAssets(assets: Asset[], category: AssetCategory): Asset[] {
    return assets.filter(asset => asset.category === category);
  }

  constructor(
    protected databaseService: DatabaseService,
    private sharedService: SharedService,
    private eventService: EventService,
    private taskService: TaskService,
    private sharedDataService: SharedDataService,
    private deviceService: DeviceService
  ) {
    super(
      databaseService,
    );
    this.watchAssetsSerachInput = this.assetsSearchInput$.asObservable();
    if (!this.deviceService.isMobile) {
      this.spaceLabours = this.sharedDataService.getSpaceLabours();
      this.spaceEquipments = this.sharedDataService.getSpaceEquipments();
      this.spaceMaterials = this.sharedDataService.getSpaceMaterials();
    }
  }

  public async filterForCurrentSpace(): Promise<void> {
    const spaceAssets = await this.databaseService.getSpaceItems('asset');
    this.itemSubject.next(spaceAssets);
  }

  public async filterForCurrentSite(): Promise<void> {
    const siteAssets = await this.databaseService.getSiteItems('asset', this.sharedService.currentSiteId);
    this.itemSubject.next(siteAssets);
  }

  public async filterAll(): Promise<void> {
    const assets = await this.databaseService.getItemsForCurrentSiteAndSpace('asset', this.sharedService.currentSiteId);
    this.itemSubject.next(assets);
  }


  /**
   * Get all space assets of given category id
   * @param category Given category id
   * @deprecated
   */
  public async filterForSpaceByCategory(category_id): Promise<void> {
    const spaceAssets = await this.databaseService.getSpaceAssets(category_id);
    this.itemSubject.next(spaceAssets);
  }

  /**
   * Check if a asset has a name and a price
   * @param asset Asset to check
   */
  checkIntegrity(asset: Asset): boolean {
    return asset.name !== '' && asset.cost >= 0;
  }

  /**
   * Method used to convert a {@link_SiteAsset} or {@link_SpaceAsset} to the {@link_EventAsset} format expected by an event
   * @param asset given asset
   */
  convertAssetToNamedEventAsset(asset: Asset): EventAsset {
    const eventAsset = new EventAsset();
    eventAsset.assetId = asset.id;
    eventAsset.amount = 1;
    eventAsset.name = asset.name;
    eventAsset.duration = 0;
    eventAsset.quantityUnit = asset.quantityUnit;
    eventAsset.jobRole = asset.jobRole;
    eventAsset.supplier = asset.supplier;
    return eventAsset;
  }

  /**
   * Method used to convert an array of {@link_SiteAsset} or {@link_SpaceAsset} to an array of {@link_EventAsset}
   */
  convertAssetsToNamedEventAssets(assets: Asset[]): EventAsset[] {
    const eventAssets = [];
    assets.forEach(asset => {
      eventAssets.push(this.convertAssetToNamedEventAsset(asset));
    });
    return eventAssets;
  }

  convertAssetsToTaskAssets(assets: Asset[]): TaskAsset[] {
    return this.convertAssetsToNamedTaskAssets(assets);
  }

  /**
   * Method used to convert an array of {@link_SiteAsset} or {@link_SpaceAsset} to an array of {@link_TaskAsset}
   */
  convertAssetsToNamedTaskAssets(assets: Asset[]): TaskAsset[] {
    const taskAssets = [];
    assets.forEach(asset => {
      taskAssets.push(this.convertAssetToNamedTaskAsset(asset));
    });
    return taskAssets;
  }

  /**
   * Method used to convert a {@link_SiteAsset} or {@link_SpaceAsset} to the {@link_TaskAsset} format expected by an event
   * @param asset given asset
   */
  convertAssetToNamedTaskAsset(asset: Asset): TaskAsset {
    const taskAsset = new TaskAsset();
    taskAsset.assetId = asset.id;
    taskAsset.amount = 1;
    taskAsset.name = asset.name;
    taskAsset.duration = 0;
    taskAsset.quantityUnit = asset.quantityUnit;
    taskAsset.jobRole = asset.jobRole;
    taskAsset.supplier = asset.supplier;
    return taskAsset;
  }

  public getLaboursLinkedToTask(task: Task): Observable<TaskAsset[]> {
    return this.labours.pipe(
      linkedToTask(task, 'asset'),
    );
  }

  public getEquipmentsLinkedToTask(task: Task): Observable<TaskAsset[]> {
    return this.equipments.pipe(
      linkedToTask(task, 'asset'),
    );
  }

  public getMaterialsLinkedToTask(task: Task): Observable<TaskAsset[]> {
    return this.materials.pipe(
      linkedToTask(task, 'asset'),
    );
  }

  public updateAssetsSearchInput(searchInput: string): void {
    this.assetsSearchInput$.next(searchInput);
  }

}
