import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Space } from '@models/space';
import { SpaceUser } from '@models/space-user';
import { Invoice } from '@models/subscription/invoice';
import { TaxRule } from '@models/subscription/tax-rule';
import { NetworkStatus } from '@models/synchronization/network-status';
import { SessionService } from '@services/session.service';
import { SpaceService } from '@services/space.service';
import { ToasterService } from '@services/toaster.service';
import { UrlGiverService } from '@services/url-giver.service';
import { UserService } from '@services/user.service';
import { NGXLogger } from 'ngx-logger';
import { BehaviorSubject, Observable, Subscription } from 'rxjs';


export type UpdateSubscription = {
  spaceId: string;
  currentPhaseSiteDiarySeats: number,
  currentPhaseSiteTaskSeats: number,
  nextPhaseSiteDiarySeats: number,
  nextPhaseSiteTaskSeats: number
}
@Injectable({
  providedIn: 'root',
})

export class SubscriptionService {

  spaceUsers: SpaceUser[];
  private spaceUsersSubject = new BehaviorSubject<SpaceUser[]>([]);
  spaceUsers$ = this.spaceUsersSubject.asObservable();
  siteDiaryUserCount: number = 0;
  siteTaskUserCount: number = 0;
  activeSiteDiaryUsers: number;
  activeSiteTaskUsers: number;
  siteDiarySelectedSeatCount: number;
  siteTaskSelectedSeatCount: number;
  siteDiaryUpdatedSeatCount: number;
  siteTaskUpdatedSeatCount: number;
  nextPhaseSiteDiarySeatCount: number;
  nextPhaseSiteTaskSeatCount: number;
  siteDiarySeatCountDifference: number;
  siteTaskSeatCountDifference: number;
  isPaymentSuccessful: boolean;
  paymentSubtotal: number;
  isVatApplied: TaxRule;
  isVatValid: boolean;
  cardDetailsError: stripe.Error;
  cardDetailsEntered: boolean = false;
  upcomingInvoiceDate: string;
  activeInvoice: Invoice;
  selectedPlanId: string;
  isSeatsReduced: boolean;
  mobileSubscriptionVersion: number;
  updateSubscriptionObject: UpdateSubscription = 
   {
    spaceId: " ",
    currentPhaseSiteDiarySeats: 0,
    currentPhaseSiteTaskSeats: 0,
    nextPhaseSiteDiarySeats: 0,
    nextPhaseSiteTaskSeats: 0
  }
  subscriptionExcludedDomains: string[];
  subscriptionStatus: string;

  constructor(
    private userService: UserService,
    private toasterService: ToasterService,
    private urlGiverService: UrlGiverService,
    private http: HttpClient,
    private logger: NGXLogger,
  ) {
    this.getSubscriptionExcludedDomains();
  }


  getSpaceMembers(spaceId: string): any{
    if (NetworkStatus.isOnline) {
      this.userService.getBackendSpaceUsers(spaceId)
        .subscribe(users => {
          this.spaceUsers = [...users];
          this.spaceUsersSubject.next(this.spaceUsers);
        });
    } else {
      this.toasterService.showWarningToaster('forbidden-offline');
    }
  }

  getSubscriptionExcludedDomains() {
    const baseUrl = this.urlGiverService.giveAPIUrl() + '/resources/subscription-excluded-domains'
    this.http.get(baseUrl).subscribe((domains) => {
      this.subscriptionExcludedDomains = domains['excluded-domains'];
    })
  }

  convertCurrencyToSymbol(currency: string): string {
    switch(currency) {
      case 'gbp':
        return '£';
      case 'usd':
        return '$';
      case 'eur':
        return '€';
      default:
        return '£';
    }
  }
}
