import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { HttpStatus } from '@constants/http/http-status';
import { FilteredEvent } from '@models/filtered-event';
import { SharedDataService } from './shared-data.service';
import { SharedService } from './shared/shared.service';
import { ToasterService } from './toaster.service';
import { UrlGiverService } from './url-giver.service';
import { taskNumberPrefix } from '@models/task';

export interface TaskFilterModel {
  parentTaskId?: string;
  taskNumber: number;
  id: string,
  title: string
}

export interface CreatorFilterModel {
  id: string,
  name: string
}

export interface EventFilterParameters {
  searchKeyword?: string,
  startDatetime?: Date,
  endDatetime?: Date,
  contractors?: string[],
  tags?: string[],
  locations?: string[],
  assets?: string[];
  creators?: string[],
  states?: string[],
  tasks?: string[],
}

@Injectable()
export class EventSearchFilterService {

constructor(
  private http: HttpClient,
  private urlGiverService: UrlGiverService,
  private sharedDataService: SharedDataService,
  private toasterService: ToasterService,
) { }

  async callSearchApi(filterParameters: EventFilterParameters): Promise<FilteredEvent[]> {
    const url = this.urlGiverService.giveEventSearchAndFilterAPIUrl(this.sharedDataService.currentSpaceId, this.sharedDataService.currentSiteId);
    const payloadBody = filterParameters;
  const filteredEvents = await this.http.post(url, payloadBody).toPromise().then((res: FilteredEvent[]) => {
    return FilteredEvent.convertJsonToFilteredEvent(res);
  }).catch((error) => {
    if(error.status === HttpStatus.PAYLOAD_TOO_LARGE) {
      this.toasterService.showWarningToaster('filter.error.payload_too_large');
    } else {
      this.toasterService.showWarningToaster('filter.error.try_again.message');
    }
    return null;
  });
  return filteredEvents;
  }

  async getTasks(): Promise<TaskFilterModel[]> {
    const url = this.urlGiverService.giveFilterDataForTasks(this.sharedDataService.currentSpaceId, this.sharedDataService.currentSiteId);
    const filterTasks = await this.http.get(url).toPromise().then((res: TaskFilterModel[]) => {
      res.map((task) => {
        if(task.taskNumber) {
          if (task.parentTaskId) {
            task.title = taskNumberPrefix.SUBTASK_PREFIX + '-' + task.taskNumber.toString() + ' ' + task.title;
          } else {
            task.title = taskNumberPrefix.TASK_PREFIX + '-' + task.taskNumber.toString() + ' ' + task.title;
          }
        }
      });
      return res;
    }).catch((error) => {
      return null;
    });
    return filterTasks;
  }

  async getCreators(): Promise<CreatorFilterModel[]> {
    const url = this.urlGiverService.giveFilterDataForEventCreators(this.sharedDataService.currentSpaceId, this.sharedDataService.currentSiteId);
    const filterCreators = await this.http.get(url).toPromise().then((res: CreatorFilterModel[]) => {
      return res;
    }).catch((error) => {
      return [];
    });
    return filterCreators;
  }

}
