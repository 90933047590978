import { Location } from '@models/location';
import { Tag } from '@models/tag';
import { Task } from '@models/task';
import { Contractor } from '@models/contractor';
import { Author } from '@models/author';
import { TaskFilterModel } from '@services/event-search-filter.service';
import { Asset } from './asset';

export class EventsFilters {
  constructor(
    public dateFrom: Date = null,
    public dateTo: Date = null,
    public selectedContractors: Contractor[] = [],
    public selectedCreators: Author[] = [],
    public selectedTags: Tag[] = [],
    public selectedTasks: Task[] | TaskFilterModel[] = [],
    public selectedStatus: string[] = [],
    public selectedLocations: Location[] = [],
    public selectedPeople: Asset[] = [],
    public selectedEquipments: Asset[] = [],
    public selectedMaterials: Asset[] = [],
  ) {
  }
}
