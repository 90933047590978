<ion-header class="secondary-header" mode="ios">
  <app-mobile-toolbar
       doneLabel="Done"
       [title]="titles[type]"
       (back)="ignoreChanges()"
       (done)="saveChanges()">
  </app-mobile-toolbar>
</ion-header>

<ion-list class="search-and-create ion-no-padding">
  <ion-item>
    <ion-searchbar
      #searchInput
      class="mt-2 no-shadow"
      animated
      showCancelButton="focus"
      placeholder="{{(canCreate ? 'mobile-search-or-create' : 'events.select.search') | translate}}"
      (ionInput)="search($event.target.value)">
    </ion-searchbar>
  </ion-item>
  <ion-item
    *ngIf="showCreationButton"
    class="list-item create-button"
    (click)="createAndSelectItem()"
    lines="none">
    <ion-label color="primary" class="create-label">
      {{'mobile-add-item' | translate}}
    </ion-label>
  </ion-item>
</ion-list>


<ion-content class="w-100">
  <ion-virtual-scroll [items]="selectableItemList | filterListByLabel:itemFilter" [itemHeight]="itemHeightFn">
    <div *virtualItem="let item">
      <ion-item lines="none" class="list-item" [class.isSelected]="item.selected">
        <ng-template [ngTemplateOutlet]="item.selected ? rowSelected : rowNotSelected" [ngTemplateOutletContext]="{$implicit: item}"></ng-template>
      </ion-item>
    </div>
  </ion-virtual-scroll>
</ion-content>

<ng-template #rowSelected let-item>
  <ng-container [ngSwitch]="selectionMode">
    <ng-template *ngSwitchCase="'multiple_basic'" [ngTemplateOutlet]="multipleSelector" [ngTemplateOutletContext]="{$implicit: item}"></ng-template>
    <ng-template *ngSwitchCase="'single'" [ngTemplateOutlet]="singleSelector" [ngTemplateOutletContext]="{$implicit: item}"></ng-template>
    <ng-template *ngSwitchCase="'multiple_with_quantity'" [ngTemplateOutlet]="complexSelector" [ngTemplateOutletContext]="{$implicit: item}"></ng-template>
    <ng-template *ngSwitchCase="'multiple_with_quantity_and_duration'" [ngTemplateOutlet]="complexSelector" [ngTemplateOutletContext]="{$implicit: item}"></ng-template>
  </ng-container>
</ng-template>

<ng-template #singleSelector let-item>
  <div class="w90" (click)="deselectItem(item)">
    <ion-label [class]="item.item.hasOwnProperty('progress')?'task-progress':''">{{item.label}}</ion-label>
    <ion-label class="task-progress" *ngIf="item.item.hasOwnProperty('progress')">{{item.item.progress}}{{'percent.symbol'| translate}} {{item.item.startDate | date : localizedFormat }} {{'hyphen.symbol'| translate}} {{item.item.endDate | date : localizedFormat }}</ion-label>
  </div>
  <div class="mark-selected">
    <ion-icon color="success" name="custom-checkmark"></ion-icon>
  </div>
</ng-template>

<ng-template #multipleSelector let-item>
  <div class="w90" (click)="deselectItem(item)">
    <ion-label>{{item.label}}</ion-label>
  </div>
  <div class="mark-selected">
    <ion-icon color="success" name="custom-checkmark"></ion-icon>
  </div>
</ng-template>

<ng-template #complexSelector let-item>
  <div class="w100 row no-gutters whitesmoke-background">
    <div class="name col-12">
      {{item.label}}
      <span *ngIf="item.item.quantityUnit">{{ this.quantityUnits[item.item.quantityUnit] }}</span>
    </div>
    <div class="item-actions col-10">
      <div class="quantity-picker w40">
        <label>
          {{ 'plugins.select.qty' | translate }}
        </label>
        <input id="{{item.item.assetId}}" name="quantity-picker" type="number"
        step="any" min="0" [(ngModel)]="item.item.amount" onclick="this.select()"
          class="form-control" (input)="limitDecimal($event, item.item)">
      </div>
      <div class="w20"></div>
      <div class="time-picker w40">
        <label *ngIf="selectionMode === 'multiple_with_quantity_and_duration'">
          {{ 'item.selector.duration' | translate }}
        </label>
        <app-duration-picker
          [readOnly]="false"
          [(duration)]="item.item.duration"
          *ngIf="selectionMode === 'multiple_with_quantity_and_duration'" class="time-form-control">
        </app-duration-picker>
      </div>
    </div>
    <div text-end class="deletion-cross col-2" (click)="deselectItem(item)">
      <ion-icon color="success" name="custom-close" class="pull-right"></ion-icon>
    </div>
  </div>
</ng-template>

<ng-template #rowNotSelected let-item>
  <div class="not-selected-row" (click)="selectItem(item)">
    <ion-label [class]="item.item.hasOwnProperty('progress')?'task-progress':''">
      <p>{{item.label}}</p>
      <p class="job-role">{{ item.item.jobRole }}</p>
      <p *ngIf="showSupplier()" class="job-role"> {{ item.item.supplier }} </p>
    </ion-label>
    <ion-label classs="task-progress" *ngIf="item.item.hasOwnProperty('progress')">{{item.item.progress}}{{'percent.symbol'| translate}} {{item.item.startDate | date : localizedFormat}} {{'hyphen.symbol'| translate}} {{item.item.endDate | date : localizedFormat}}</ion-label>
    <ion-label *ngIf="item.item.quantityUnit">{{ this.quantityUnits[item.item.quantityUnit] }}</ion-label>
  </div>
</ng-template>
