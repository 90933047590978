import { BreakpointObserver } from '@angular/cdk/layout';
import {
  Component,
  HostListener,



  Input, OnDestroy,
  OnInit,
  ViewChild
} from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MAT_MOMENT_DATE_FORMATS, MomentDateAdapter } from '@angular/material-moment-adapter';
import { DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE } from '@angular/material/core';
import { ActivatedRoute, Router } from '@angular/router';
import { AlertController, IonItemSliding, ModalController, IonSearchbar } from '@ionic/angular';
import { Attachment } from '@models/attachment';
import { Author } from '@models/author';
import { Contractor } from '@models/contractor';
import { Event, EventStatus } from '@models/event';
import { EventsFilters } from '@models/events-filters';
import { Location } from '@models/location';
import { NetworkStatus } from '@models/synchronization/network-status';
import { Tag } from '@models/tag';
import { Task, TaskState } from '@models/task';
import { MultiSelectItem } from '@models/utils/multi-select-item';
import { NgbDateAdapter, NgbDateNativeAdapter, NgbDateParserFormatter, NgbDatepickerI18n, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { TranslateService } from '@ngx-translate/core';
import { AttachmentService } from '@services/attachment.service';
import { ContractorService } from '@services/contractor.service';
import { DateManipulationService } from '@services/date-manipulation.service';
import { DeviceService } from '@services/device.service';
import { EventService } from '@services/event.service';
import { ExportService, SummaryType } from '@services/export.service';
import { FormatSortService } from '@services/format-sort.service';
import { CustomDatepickerI18nService, NgbDateParserFormatterI18N } from '@services/i18n/date-internationalization.service';
import { LocationService } from '@services/location.service';
import { Logger } from '@services/logger';
import { PageTitleService } from '@services/page-title.service';
import { SharedService } from '@services/shared/shared.service';
import { SpinnerService } from '@services/spinner.service';
import { SyncStatusService } from '@services/synchronization/sync-status.service';
import { SyncService } from '@services/synchronization/sync.service';
import { TabTitleService } from '@services/tab-title.service';
import { TagService } from '@services/tag.service';
import { TaskService } from '@services/task.service';
import { ToasterService } from '@services/toaster.service';
import { UserRole } from '@services/user-rights/user-rights-dao.service';
import { UserRightsService } from '@services/user-rights/user-rights.service';
import { UserService } from '@services/user.service';
import { DatatableComponent } from '@swimlane/ngx-datatable';
import { UiWebDatetimePickerComponent } from 'app/core/ui/components/web';
import { UiDatetimePickerService } from 'app/core/ui/services';
import { FabButtonService } from 'app/shared/components/fab-button/fab-button.service';
import * as moment from 'moment';
import { Subject, Subscription } from 'rxjs';
import { filter, take, takeUntil } from 'rxjs/operators';
import { ExportSelectorPopupComponent } from '../../export-selector-popup/export-selector-popup.component';
import { FiltersSelectorPopupComponent } from '../../filters-selector-popup/filters-selector-popup.component';
import { PdfExportConfigurationPopupComponent } from './pdf-export-configuration-popup/pdf-export-configuration-popup.component';
import { SummaryConfigurationPopupWebappComponent } from './summary-configuration-popup/summary-configuration-popup-webapp/summary-configuration-popup-webapp.component';
import { IntercomService } from '@services/intercom.service';
import { AutoUnsubscribeComponent } from 'app/shared/components/subscriptions/auto-unsubscribe.component';
import { FilterEvents } from '../../../../pipes/filter-events.pipe';
import { ScreenManipulationService } from '@services/screen-manipulation.service';
import { NGXLogger } from 'ngx-logger';
import { MenuService } from '@services/menu.service';
import { IntercomUserActions } from '@models/enums/intercom-user-actions';
import { AppRateSettingsService } from '@services/app-rate-settings.service';
import { SiteService } from '@services/site.service';
import { CustomEventFormCreatorMobileComponent } from 'app/home/space/custom-event-form-creator/custom-event-form-creator-mobile.component';
import { Site } from '@models/site';
import { PostStatusService, PostStatusStates } from '@services/post-status.service';
import { UserAppAccessService } from '@services/user-app-access/user-app-access.service';
import { AppUpdateSettingsService } from '@services/app-update-settings.service';
import { AuthService } from '@services/auth/auth.service';
import { SpaceService } from '@services/space.service';
import { SessionService } from '@services/session.service';
import { UserSettingsService } from '@services/user-settings.service';
import { QueueService } from '@services/synchronization/queue.service';
import { GlobalConstants } from '@constants/global-constants';

@Component({
  selector: 'app-event-list',
  templateUrl: './event-list.component.html',
  styleUrls: ['./event-list.component.scss'],
  providers: [
    {
      provide: DateAdapter,
      useClass: MomentDateAdapter,
      deps: [MAT_DATE_LOCALE]
    },
    { provide: MAT_DATE_FORMATS, useValue: MAT_MOMENT_DATE_FORMATS },
    {
      provide: NgbDateAdapter,
      useClass: NgbDateNativeAdapter
    },
    { provide: NgbDatepickerI18n, useClass: CustomDatepickerI18nService },
    { provide: NgbDateParserFormatter, useClass: NgbDateParserFormatterI18N }
  ]
})
export class EventListComponent extends AutoUnsubscribeComponent implements OnInit, OnDestroy {
  showUpdateBanner: boolean = false;

  //#region Task to filter
  @Input()
  set taskToFilter(idTaskToFilter: string | null) {
    if (idTaskToFilter) {
      this.taskService.getOnceById(idTaskToFilter)
        .then(task => {
          this.initializeFilters();
          this.eventsFilters.selectedTasks.push(task);
          this.eventsFilters.dateFrom = this.dateManipulationService.getXDaysAgo(task.startDatetime, 60);
          this.daysToDisplayMobile = this.dateManipulationService.getNbDaysBetweenDates(task.startDatetime, new Date) + 30;
          this.selectedTaskItems = [task];
          if (this.deviceService.isMobile) {
            this.loadMoreEvents(true);
          } else {
            this.search();
          }
        });
    }
  }

  readonly UserRole = UserRole;
  readonly EventStatus = EventStatus;

  /**
   * Filter Contractor list
   */
  contractors: Contractor[] = [];

  /**
   * Filter Tag list
   */
  tags: Tag[] = [];

  /**
   * Filter Location list
   */
  locations: Location[] = [];

  /**
   * Filter Task list
   */
  tasks: Task[] = [];

  /**
   * Filter Creator list
   */
  creators: Author[] = [];

  readonly localizedFormat: string;

  /**
   * NG Multiselect Contractors settings
   */
  multiselectContractorSettings = {};

  /**
   * NG Multiselect Tags settings
   */
  multiselectTagSettings = {};

  /**
   * NG Multiselect Status settings
   */
  multiselectStatusSettings = {};

  /**
   * NG Multiselect Creators settings
   */
  multiselectCreatorSettings = {};

  /**
  * NG Multiselect Tasks settings
  */
  multiselectTaskSettings = {};

  /**
   * NG Multiselect Location settings
   */
  multiselectLocationSettings = {};

  /**
   * NG Multiselect selected contractors
   */
  _selectedContractorItems: MultiSelectItem[];

  /**
   * NG Multiselect selected tags
   */
  _selectedTagItems: MultiSelectItem[];

  /**
   * NG Multiselect selected status
   */
  _selectedStatusItems: MultiSelectItem[];

  /**
   * NG Multiselect selected creators
   */
  _selectedCreatorItems: MultiSelectItem[];

  /**
   * NG Multiselect selected tasks
   */
  _selectedTaskItems: Task[];

  /**
   * NG Multiselect selected locations
   */
  _selectedLocationItem: MultiSelectItem[];

  // @TODO: Refactor Ngx datatable component => unmaintainable
  /**
   * NGX datatable component
   */
  @ViewChild(DatatableComponent) table: DatatableComponent;

  /**
   * NGX datatable search input
   */
  @ViewChild('searchInput') searchInput: IonSearchbar;

  /**
   * NGX datatable event list
   */
  events: Event[] = [];

  /**
   * NGX datatable filtered event list to display
   */
   filteredEvents: Event[] = [];

   /**
   * Filtered event list thats updated and used to display the events only when input changes in the searchbar
   */
   searchableFilteredEvents: Event[] = [];

  /**
   * NGX datatable earlier event list
   */
  earlierEvents: Event[] = [];

  /**
   * NGX datatable current day event list
   */
  todaysEvents: Event[] = [];

   /**
   * NGX datatable yesterday event list
   */
  yesterdaysEvents: Event[] = [];

  /**
   * NGX datatable last week event list
   */
  lastWeeksEvents: Event[] = [];

  /**
   * NGX datatable item per page value
   */
  itemPerPage: number;

  /**
   * NGX datatable scrollable test
   */
  canScrollDatatable: boolean;

  /**
   * NGX datatable custom compare string
   */
  readonly customComparString = FormatSortService.sortText;

  /**
   * NGX datatable custom compare author
   */
  readonly customComparAuthor = FormatSortService.sortAuthor;

  /**
   * NGX datatable dataloading state
   */
  dataLoading: boolean = true;

  /**
   * Events filters
   */
  eventsFilters: EventsFilters;

  /**
   * Events status (pending, approved, rejected)
   */
  status: TaskState[] = [];

  /**
   * Array containing the last 7 days of the week
   */
  readonly lastSevenDays: moment.Moment[];

  /**
   * Store last date fetched when fetching earlier events
   */
  lastDateFrom: Date;

  /**
   * Boolean to check if user has already fetched earlier events
   */
  hasFetchEarlierEvents: boolean;

  /**
   * Boolean to check state of swipe icon on mobile (arrow up or down on swipe)
   */
  swipeIconDown: boolean;

  /**
   * Array containing today and the previous day
   */
  readonly lastTwoDays: moment.Moment[];

  numberOfFaultyItems = 0;

  totalEventsLength: number = 0;
  shownEventsLength: number = 0;
  areAllEventsShown: boolean = false;
  canCreateEvent: boolean = false;
  userHasAppAccess: boolean = false;
  showLimitedSearchBanner: boolean = false;

  isTabletInLandscape:boolean = false;
  isTabletInLandscapeModeSubscription: Subscription;

  readonly isOnline = NetworkStatus.watchIsOnline();

  private daysToDisplayMobile: number;

  private totalQueueCount: number = 0;
  private currentQueueCount: number = 0;
  showUploadInProgress: boolean = false;
  eventNumberPrefix = GlobalConstants.diaryEventNumberPrefix;

  @HostListener('window:resize', ['$event'])
  onResize(event) {
    this.checkScrollHorizontal();
  }

  constructor(
    private sharedService: SharedService,
    private eventService: EventService,
    private router: Router,
    private contractorService: ContractorService,
    private locationService: LocationService,
    private userService: UserService,
    private breakpointObserver: BreakpointObserver,
    private toasterService: ToasterService,
    public translate: TranslateService,
    public deviceService: DeviceService,
    public dateManipulationService: DateManipulationService,
    private alertController: AlertController,
    private pageTitleService: PageTitleService,
    private tabTitleService: TabTitleService,
    private tagService: TagService,
    public userRightsService: UserRightsService,
    private activatedRoute: ActivatedRoute,
    public modalController: ModalController,
    private attachmentService: AttachmentService,
    private spinnerService: SpinnerService,
    private datetimePickerService: UiDatetimePickerService,
    private dialog: MatDialog,
    private customDatepickerI18nService: CustomDatepickerI18nService,
    private exportService: ExportService,
    private modalService: NgbModal,
    private taskService: TaskService,
    private syncStatusService: SyncStatusService,
    private fabButtonService: FabButtonService,
    private syncService: SyncService,
    private intercomService: IntercomService,
    private filterEventsPipe: FilterEvents,
    private screenManipulationService: ScreenManipulationService,
    private logger: NGXLogger,
    private menuService: MenuService,
    private appRateSettingsService: AppRateSettingsService,
    private siteService: SiteService,
    private postStatusService: PostStatusService,
    private userAppAccessService: UserAppAccessService,
    private appUpdateSettingsService: AppUpdateSettingsService,
    private authService: AuthService,
    private spaceService: SpaceService,
    private sessionService: SessionService,
    private userSettingsService: UserSettingsService,
    private queueService: QueueService
  ) {
    super();
    this.checkAndDeleteAnyExcessEvents();
    this.localizedFormat = this.customDatepickerI18nService.getPickerDisplayFormatWithOutTime();
    this.initializeFilters();
    this.daysToDisplayMobile = 7;
    this.lastSevenDays = this.dateManipulationService.getArrayOfLastDays(7);
    this.lastTwoDays = this.dateManipulationService.getArrayOfLastDays(1);
    this.tagService.items.pipe(
      takeUntil(this.destroy),
    ).subscribe(tags => this.tags = tags);
    this.locationService.items.pipe(
      takeUntil(this.destroy),
    ).subscribe(locations => this.locations = locations);
    this.contractorService.items.pipe(
      takeUntil(this.destroy),
    ).subscribe(contractors => this.contractors = contractors);
    this.userService.getSiteUsers(this.sharedService.currentSpaceId, this.sharedService.currentSiteId).pipe(
      takeUntil(this.destroy),
    ).subscribe(users => {
      this.creators = users.map(user => new Author(user.id, user.firstName + ' ' + user.lastName));
      this.checkAndAddMissingEventCreators();
    });

    if(deviceService.isMobile && deviceService.hasCordova) {
      const appRateSettings = this.appRateSettingsService.get();
      this.appRateSettingsService.set('usesUntilPrompt', appRateSettings.usesUntilPrompt + 1);

      if (appRateSettings.usesUntilPrompt > 30 && appRateSettings.showRateDialog) {
        (<any>cordova.plugins).AppReview.requestReview()
          .then(() => {
            this.appRateSettingsService.set('usesUntilPrompt', 0);
            this.appRateSettingsService.set('numberOfPopUp', appRateSettings.numberOfPopUp + 1);
            if (appRateSettings.numberOfPopUp >= 2) {
              this.appRateSettingsService.set('showRateDialog', false);
            }
          });
      }
    }

    this.appUpdateSettingsService.watchAppUpdateSettings().pipe(
      takeUntil(this.destroy),
    ).subscribe(appUpdateSettings => {
      if(appUpdateSettings.showAppUpdateBanner) {
        this.showUpdateBanner = true;
      }
    })
  }

  ngOnInit() {
    this.authService.display2FAModals();

    this.checkForShowingEventFormPopup();
    this.fetchFaultyItemsCount();
    this.fetchFaultyItemsCountOnError();
    this.checkForFetchingOfSpaceData();
    this.checkForFetchingOfSites();

    this.userAppAccessService.watchCurrentAppAccess().subscribe((userAppAccess) => {
      this.userHasAppAccess = this.userAppAccessService.userHasCorrectAppAccess();
    });

    this.menuService.updateMenuContent();
    if(!this.deviceService.isMobile) {
      this.fabButtonService.hide();
    }
    this.getTotalEventsLength();

    if (this.sharedService.canSyncSpinnerActivate) {
      this.spinnerService.activate('rotating', this.translate.instant('sync.loading-data'));
      this.sharedService.setCanSyncSpinnerActivate(false);
    }

    if(this.deviceService.isMobile) {
      this.syncStatusService.watchSiteSyncStatus.pipe(take(1)).subscribe((syncStatus) => {
        if(syncStatus.status !== 'in-progress') {
          this.eventService.filterEventsInMobile(
            moment(new Date()).startOf('day').subtract(this.daysToDisplayMobile, 'days').toDate(),
            this.eventsFilters.dateTo
          ).then(async () => {
          this.fetchAndPopulateEvents();
          });
        } else {
          this.syncStatusService.waitForEndOfSiteSync().then(() => {
            this.eventService.filterEventsInMobile(
              moment(new Date()).startOf('day').subtract(this.daysToDisplayMobile, 'days').toDate(),
              this.eventsFilters.dateTo
            ).then(async () => {
              this.fetchAndPopulateEvents();
            });
          });
        }
      });
    } else {
      this.fetchAndPopulateEvents();
    }


    this.syncStatusService.waitForEndOfSiteSync().then(() => {
      // we wait until 1st synchronization finishes to load and display event list with site contractors and site tags labels
      this.spinnerService.deactivate();
      if(!this.deviceService.isMobile) {
        this.updateEventsFilter(true);
      }
    });

    // Set the current title
    this.pageTitleService.emitNewPageTitle('menu.events');
    this.tabTitleService.updateTabTitle('menu.events');

    this.tagService.filterForCurrentSite();
    this.contractorService.filterForCurrentSite();
    this.locationService.filterForCurrentSite();

    this.sharedService.watchCollapseSidebar.pipe(
      takeUntil(this.destroy),
    ).subscribe(() => {
      this.recalculateTable();
    });

    this.taskService.items.pipe(
      takeUntil(this.destroy),
    ).subscribe(tasks => {
      this.tasks = tasks;
    });

    if (!this.deviceService.isMobile) {

      this.status[0] = { name: 'events.list.draft', id: EventStatus.DRAFT, active: true, color: 'warning', itemName: null };
      this.status[1] = { name: 'events.list.pendingApproval', id: EventStatus.SUBMITTED, active: true, color: 'primary', itemName: null };
      this.status[2] = { name: 'events.list.approved', id: EventStatus.APPROVED, active: true, color: 'light-green', itemName: null };
      this.status[3] = { name: 'events.list.rejected', id: EventStatus.REJECTED, active: true, color: 'danger', itemName: null };
      this.status.forEach(s => this.translate.get(s.name).subscribe(translation => s.itemName = translation));

      let multiselectSettings: any = {
        classes: 'angular2-multiselect',
        badgeShowLimit: 2,
        labelKey: 'name',
        primaryKey: 'id',
        enableSearchFilter: true,
        searchBy: ['name'],
      };

      this.translate.get(['filter.contractors', 'filter.tags', 'filter.status', 'filter.creators', 'filter.locations',
        'filter.select_all', 'filter.unselect_all', 'filter.tasks',
        'filter.search', 'filter.no_results', 'filter.select_all_filtered', 'filter.unselect_all_filtered'])
        .pipe(
          takeUntil(this.destroy),
        ).subscribe(translations => {
          multiselectSettings = {
            ...multiselectSettings,
            selectAllText: translations['filter.select_all'],
            unSelectAllText: translations['filter.unselect_all'],
            searchPlaceholderText: translations['filter.search'],
            noDataLabel: translations['filter.no_results'],
            filterSelectAllText: translations['filter.select_all_filtered'],
            filterUnSelectAllText: translations['filter.unselect_all_filtered'],
          };
          this.multiselectContractorSettings = {
            ...multiselectSettings,
            text: translations['filter.contractors'],
          };
          this.multiselectTagSettings = {
            ...multiselectSettings,
            text: translations['filter.tags'],
          };
          this.multiselectTaskSettings = {
            ...multiselectSettings,
            text: translations['filter.tasks'],
            labelKey: 'title',
            searchBy: ['title'],
          };
          this.multiselectStatusSettings = {
            ...multiselectSettings,
            text: translations['filter.status'],
            labelKey: 'itemName',
            enableSearchFilter: false,
            enableCheckAll: false,
          };
          this.multiselectCreatorSettings = {
            ...multiselectSettings,
            text: translations['filter.creators'],
            labelKey: 'name',
          };
          this.multiselectLocationSettings = {
            ...multiselectSettings,
            text: translations['filter.locations'],
            labelKey: 'name',
          };
        });

      // When switching site, reset last date from to 7 days ago
      this.lastDateFrom = moment(new Date()).startOf('day').subtract(30, 'days').toDate();

      // NGX datatable default paramter values
      this.itemPerPage = 15;
      this.checkScrollHorizontal();
    } else {
      this.spinnerService.activate('rotating');

      this.hasFetchEarlierEvents = false;
    }

    // When you refresh the browser the user rights take some time to be received from the indexedDB so we have to wait for them to know if we can show the button
    this.userRightsService.watchHasRight(this.userRightsService.USER_RIGHTS.site.event.create).pipe(
      takeUntil(this.destroy),
    ).subscribe(hasEventCreationRights => {
      if (hasEventCreationRights) {
        this.fabButtonService.fabClickedSubject.pipe(
          takeUntil(this.destroy),
          filter(state => state)
        ).subscribe(state => {
          this.fabButtonService.reset();
          this.goToNew();
        });
        if(this.deviceService.isMobile) {
          this.fabButtonService.show();
        }
      }
    });

    this.userRightsService.watchCurrentRoles().subscribe(userRoles => {
      this.canCreateEvent = this.userRightsService.userHasRight(userRoles, UserRightsService.USER_RIGHTS.site.event.create);
    });

    // checks if device is a tablet in landscape mode and updates the view accordingly
    if(this.deviceService.isTablet) {
      this.isTabletInLandscapeModeSubscription = this.screenManipulationService.isTabletInLandscapeMode.subscribe((value) => {
        this.isTabletInLandscape = value;
        this.screenManipulationService.updateIsSidebarOpenedInLandscape(value);
      });
    }

    // Handle upload in progress
    if (this.deviceService.isMobile) {
      this.queueService.queueCount$.pipe(
        takeUntil(this.destroy)
      ).subscribe(count => {
        if (count === 0) {
          this.currentQueueCount = this.totalQueueCount;
          if (this.showUploadInProgress) {
            setTimeout(() => {
              this.showUploadInProgress = false;
              this.currentQueueCount = 0;
              this.totalQueueCount = 0;
            }, 1000);
          }
        } else {
          this.showUploadInProgress = true;
          if (this.totalQueueCount === 0 && this.currentQueueCount === 0) {
            this.totalQueueCount = count;
          } else {
            if (count >= this.totalQueueCount) {
              this.totalQueueCount = count;
            } else {
              this.currentQueueCount = this.totalQueueCount - count;
            }
          }
        }
      });
    }
  }

  async checkAndDeleteAnyExcessEvents(): Promise<void> {
    const excessEvents = await this.sharedService.checkAndReturnAnyExcessEvents();
    if(excessEvents && excessEvents.length > 0) {
      await this.eventService.deleteMany(excessEvents);
    }
  }

  // Display a spinner when ever the sequence tokens are cleared after an update
  async checkAndDisplaySpinnerAfterUpdate(): Promise<void> {
    if(localStorage.getItem('clearedSequenceTokenAfterUpdate')) {
      this.syncStatusService.communicateSiteSyncAfterUpdateStatus('in-progress', this.sharedService.currentSpaceId, this.sharedService.currentSiteId);
      this.spinnerService.activate('rotating', "sync.loading-data");
      this.syncStatusService.waitForEndOfSiteSyncAfterUpdate().then(() => {
        localStorage.removeItem('clearedSequenceTokenAfterUpdate');
        this.spinnerService.deactivate();
      });
    }
  }

  fetchAndPopulateEvents(): void {
    this.eventService.items.pipe(
      takeUntil(this.destroy),
    ).subscribe((events) => {
      if (events !== undefined) {
        events.sort(((a, b) => <any>b.startDatetime - <any>a.startDatetime));
        events.forEach((event) => {
          if (event.tags.length > 0) {
            event.tags.forEach(async (tag) => {
              const newTag = await this.tagService.getTagById(tag.tagId);
              if (newTag) {
                tag.name = newTag.name;
              }
            });
          }
          if (event.contractors.length > 0) {
            event.contractors.forEach(async (contractor) => {
              const newContractor = await this.contractorService.getOnceById(contractor.contractorId);
              if (newContractor) {
                contractor.name = newContractor.name;
              }
            });
          }
          if (event.locationObject) {
            this.locationService.getOnceById(event.locationObject.id).then((newLocation) => {
              if (newLocation) {
                event.locationObject.name = newLocation.name;
              }
            });
          }
        });

        if (!this.deviceService.isMobile) {
          this.events = events;
          const filteredEvents = this.filterEventsPipe.transform(events,this.eventsFilters,null);
          this.searchableFilteredEvents = filteredEvents;
          this.updateSearchedEvents();
        } else {
          this.events = events.filter(event => event.startDatetime >= this.dateManipulationService.get7DaysAgo(new Date()));
          this.earlierEvents = events.filter(event => event.startDatetime < this.dateManipulationService.get7DaysAgo(new Date()));
          this.separateEventsByDate();

          this.shownEventsLength = this.todaysEvents.length + this.yesterdaysEvents.length + this.lastWeeksEvents.length + this.earlierEvents.length;
          this.getTotalEventsLength().then(() => {
            if (this.totalEventsLength === this.shownEventsLength) {
              this.areAllEventsShown = true;
            } else {
              this.areAllEventsShown = false;
            }
            if(this.totalEventsLength !== 0 && this.shownEventsLength === 0) {
              this.loadMoreEvents();
            }
          });
          this.spinnerService.deactivate();
          this.checkAndDisplaySpinnerAfterUpdate();
          this.filterEventsInMobile();
        }
        this.dataLoading = false;

        this.swipeIconDown = false;
        this.hasFetchEarlierEvents = true;
      }
    });
  }

  /**
   * separates events into today, yesterday and last weeks events to display
   */
  separateEventsByDate(): void {
    this.todaysEvents = this.events.filter(event => event.startDatetime > moment().startOf('day').toDate());
    this.yesterdaysEvents = this.events.filter(event => (event.startDatetime > moment(new Date()).startOf('day').subtract(1, 'days').toDate()) && (event.startDatetime < moment().startOf('day').toDate()));
    this.lastWeeksEvents = this.events.filter(event => (event.startDatetime < moment(new Date()).startOf('day').subtract(1, 'days').toDate()) && (event.startDatetime >= this.dateManipulationService.get7DaysAgo(new Date())));
  }

  closeAppUpdateBanner(): void {
    this.showUpdateBanner = false;
    this.appUpdateSettingsService.setAppUpdateSettings("showAppUpdateBanner", false);
  }

  goToAppStore(): void {
    this.appUpdateSettingsService.goToAppStore();
  }

  ngOnDestroy(): void {
    super.ngOnDestroy();
    this.fabButtonService.hide();
    if(this.deviceService.isTablet) {
      this.isTabletInLandscapeModeSubscription.unsubscribe();
    }
  }

  /**
   * Add any missing event creators(for example a user was deleted from the Site) from the list of Site Users to the creators filter list
   */
  checkAndAddMissingEventCreators(): void {
    this.eventService.getAllEventCreators().then((eventCreators) => {
      let nonSiteUserCreators: Author[] = [];
      eventCreators.forEach((eventCreator) => {
        if((this.creators.find(creator => creator.id === eventCreator.id)) === undefined) {
          nonSiteUserCreators.push(eventCreator);
        }
      });
      this.creators.push(...nonSiteUserCreators);
    });
  }

  async getTotalEventsLength() {
    this.totalEventsLength = await this.eventService.fetchSiteEventsCount();
  }

  // Method Triggered on activation of a row (ex. click on an event)
  onActivateRow($event) {
    if ($event.type === 'click') {
      // Navigate to event
      this.router.navigate(['../' + $event.row.id], { relativeTo: this.activatedRoute });
    }
  }

  swapSearchBar(): void {
    // Very hack-ish way to swap search bar and "Select all"
    const searchbars = document.querySelectorAll('angular2-multiselect .list-area .list-filter');
    for (let i = 0; i < searchbars.length; i++) {
      const searchbar = searchbars[i];
      searchbar.parentNode.insertBefore(searchbar, searchbar.parentNode.firstChild);
    }
  }

  /**
   *  Getter/setters to watch selected items
   */

  get selectedContractorItems(): MultiSelectItem[] {
    return this._selectedContractorItems;
  }
  set selectedContractorItems(value: MultiSelectItem[]) {
    this._selectedContractorItems = value;
    this.eventsFilters.selectedContractors = this.contractors.filter(
      contractor => value.some(selectedContractor => contractor.id === selectedContractor.id)
    );
  }
  get selectedTagItems(): MultiSelectItem[] {
    return this._selectedTagItems;
  }
  set selectedTagItems(value: MultiSelectItem[]) {
    this._selectedTagItems = value;
    this.eventsFilters.selectedTags = this.tags.filter(tag => value.some(selectedTag => tag.id === selectedTag.id));
  }
  get selectedStatusItems(): MultiSelectItem[] {
    return this._selectedStatusItems;
  }
  set selectedStatusItems(value: MultiSelectItem[]) {
    this._selectedStatusItems = value;
    this.eventsFilters.selectedStatus = this.status.filter(status => value.some(selectedStatus => status.id === selectedStatus.id)).map(status => '' + status.id);
  }
  get selectedCreatorItems(): MultiSelectItem[] {
    return this._selectedCreatorItems;
  }
  set selectedCreatorItems(value: MultiSelectItem[]) {
    this._selectedCreatorItems = value;
    this.eventsFilters.selectedCreators = this.creators.filter(creator => value.some(selectedCreator => creator.id === selectedCreator.id));
  }
  get selectedTaskItems(): Task[] {
    return this._selectedTaskItems;
  }
  set selectedTaskItems(value: Task[]) {
    this._selectedTaskItems = value;
    this.eventsFilters.selectedTasks = this.tasks.filter(task => value.some(selectedTask => task.id === selectedTask.id));
  }
  get selectedLocationItem(): MultiSelectItem[] {
    return this._selectedLocationItem;
  }
  set selectedLocationItem(value: MultiSelectItem[]) {
    this._selectedLocationItem = value;
    this.eventsFilters.selectedLocations = this.locations.filter(location => value.some(selectedLocation =>
      location.id === selectedLocation.id));
  }

  updateEventsFilter(isDefaultFilter?: boolean): void {
    this.dataLoading = true;
    if(this.deviceService.isMobile) {
      this.callMobileFiltersFunction();
    } else {
      this.eventService.filterEvents(
       this.eventsFilters.dateFrom, this.eventsFilters.dateTo
      ).then(async () => {
        // mobile doesn't make use of this method to filter events and as such only for the web app
        // the events are being filtered here
        if(!this.deviceService.isMobile) {
          let filteredEvents = this.filterEventsPipe.transform(this.events,this.eventsFilters,null);

          if(isDefaultFilter){
            // If last 30 days do not have any event then show last 30 events
            if(!filteredEvents.length) {
              let events = await this.eventService.fetchLast30Events();
              const eventCount = events.length;

              if(eventCount) {
                filteredEvents = events;
                // setting the dateFrom property in eventFilter according to the events shown
                const earliestEventDateTime = events[eventCount-1].startDatetime;
                this.eventsFilters.dateFrom = new Date(earliestEventDateTime);
              }

              return;
            }
          }

          this.searchableFilteredEvents = filteredEvents;
          this.updateSearchedEvents();
        }
      });
    }
  }

  /**
   * Open events filters in modal for mobiles scenarios
   */
  async openFiltersModal() {
    const modal = await this.modalController.create({
      component: FiltersSelectorPopupComponent,
      componentProps: {
        eventsFilters: this.eventsFilters,
        resetFiltersFunction: this.initializeMobileFilters.bind(this),
        callMobileFiltersFunction: this.callMobileFiltersFunction.bind(this)
      },
    });
    return await modal.present();
  }

  callMobileFiltersFunction(): void {
    this.isEventsFilterPresent();
    this.eventService.filterEventsInMobile(
      moment(new Date()).startOf('day').subtract(this.daysToDisplayMobile, 'days').toDate(),
      this.eventsFilters.dateTo
    );
  }

  filterEventsInMobile(): void {
    this.events = this.filterEventsPipe.transform(this.events, this.eventsFilters, this.searchInput.value ? this.searchInput.value : '');
    this.earlierEvents = this.filterEventsPipe.transform(this.earlierEvents, this.eventsFilters, this.searchInput.value ? this.searchInput.value : '');
    this.separateEventsByDate();
  }

  initializeMobileFilters(): void {
    this.initializeFilters();
    this.callMobileFiltersFunction();
  }

  onMobileSearchInputChange(value: string): void {
    this.callMobileFiltersFunction();
  }

  private warnOfflineUser(): void {
    this.toasterService.showWarningToaster('events.export.offline');
  }

  /**
   * Open export types in modal for mobiles scenarios
   */
  async openExportModal() {
    if(this.userHasAppAccess) {
      if (NetworkStatus.isOnline) {
        const modal = await this.modalController.create({
          component: ExportSelectorPopupComponent,
          componentProps: {
            eventsFilters: this.eventsFilters,
            /** sending the list of events(along with earlier Events list) unfiltered
            * as in mobile device date input happens at the last.
            */
            listOfEvents: this.events.concat(this.earlierEvents),
            searchInput: this.searchInput.value,
            includedEvents: this.filterEventsPipe.transform(
                this.events,
                this.eventsFilters,
                this.searchInput.value
              ).map(event => event.id),
          },
        });
        return await modal.present();
      }
      else {
        this.warnOfflineUser();
      }
    } else {
      this.userAppAccessService.showUpdateAppAccessModal();
    }
  }

  showTooltip(event: any, tooltip: any) {
    event.stopPropagation(); // Prevents accidental closing
    event.preventDefault();
    tooltip.show(); // Show tooltip manually
  }


  updateFiltersFromDatepicker(type: string): void {
    const date = type === 'endDatetime' ? this.datetimePickerService.endDatetime : this.datetimePickerService.startDatetime;
    if (type === 'startDatetime') {
      this.triggerDateSearch('from', date);
    } else {
      this.triggerDateSearch('to', date);
    }
  }

  async openDatePicker(type: 'startDatetime' | 'endDatetime') {
    this.datetimePickerService.type = type;
    this.datetimePickerService.hideTime = true;
    const dialogRef = this.dialog.open(UiWebDatetimePickerComponent, {
      width: '350px',
    });
    dialogRef.componentInstance.customSaveAction = () => this.updateFiltersFromDatepicker(type);
  }

  /**
   * get Events with filters
   */
  search(): void {
    this.updateEventsFilter();
    // Reset search input
    if (this.searchInput) {
      this.searchInput.value = '';
    }
  }

  /**
   * Method triggered when from date or to date is changed in ngbDatepicker
   */
  triggerDateSearch(type: string, date: Date): void {
    if (type === 'from') {
      this.eventsFilters.dateFrom = date;
    } else {
      this.eventsFilters.dateTo = date;
    }
  }


  /** DATA PROCESSING FUNCTIONS ********************************/
  getStatus(index): TaskState {
    return this.status.find(s => s.id === index);
  }

  /*********************************************************/

  /** NGX DATATABLE FUNCTIONS ********************************/
  /**
   * Function listening to item per page changes in app-footer component
   * @param nb Number of item per page to show -> given by child component app-footer
   */
  receiveItemPerPage(nb: number): void {
    this.itemPerPage = nb;
    if (this.table) {
      this.table.offset = 0;
    }
  }

  /**
  * Check if datatable should be responsive => scroll bar horizontal should appear
  */
  checkScrollHorizontal(): void {
    this.canScrollDatatable = this.breakpointObserver.isMatched('(max-width: 1350px)');
  }

  /**
  * Method to recalculate size of datatable columns (used when available space changes, ex: collapse of sidebar )
  */
  recalculateTable(): void {
    this.table.recalculate();
    this.events = [...this.events];
    this.filteredEvents = [...this.filteredEvents];
  }


  /**
   * Function to search events based on value in search input
   */
  updateSearchFilter(): void {
    // filter events depending of search input value
    if (!this.deviceService.isMobile) {
      this.updateEventsFilter();
    }
    // go back to the first page
    if (this.table) {
      this.table.offset = 0;
    }
  }
  /*********************************************************/

  invalidDatesInFilter(): boolean {
    return this.eventsFilters.dateFrom && this.eventsFilters.dateTo && this.eventsFilters.dateFrom.getTime() > this.eventsFilters.dateTo.getTime();
  }

  /**
   * Reset all filters and launch a search
   */
  initializeFilters(): void {
    let dateFrom = moment(new Date()).startOf('day');
    // We show one week by default on mobile, one month by default on webapp
    dateFrom = this.deviceService.isMobile ? dateFrom.subtract(7, 'days') : dateFrom.subtract(1, 'month');
    const dateTo = moment(new Date()).endOf('day');
    this.eventsFilters = new EventsFilters(
      dateFrom.toDate(),
      dateTo.toDate()
    );
    this.datetimePickerService.startDatetime = this.eventsFilters.dateFrom;
    this.datetimePickerService.endDatetime = this.eventsFilters.dateTo;
  }

  /***************************************************/

  /**
   * Open PDF modal to configure PDF summary generation
   */
  openPdfSummaryModal(): void {
    if(this.userHasAppAccess) {
      this.exportService.checkSubscription('REPORT_SUMMARY').pipe(
        takeUntil(this.destroy),
      ).subscribe(() => {
        const dialogRef = this.dialog.open(
          SummaryConfigurationPopupWebappComponent,
          { width: '600px', },
        );
        const summaryConfigurationPopupWebappComponent: SummaryConfigurationPopupWebappComponent = dialogRef.componentInstance;
        summaryConfigurationPopupWebappComponent.setCallbacks(
          (type, fromDate, title) => {
            this.generateSummaryPdf(type, fromDate, title);
          },
          () => {
            dialogRef.close();
          }
        );
      });
    } else {
      this.userAppAccessService.showUpdateAppAccessModal();
    }
  }

  /**
   * Download PDF Daily Summary
   */
  generateSummaryPdf(type: SummaryType, fromDate: Date, title: string): void {
    this.spinnerService.activate('rotating');
    this.exportService.fetchAndSaveEventSummaryPDF(type, fromDate, title).pipe(
      takeUntil(this.destroy),
    ).subscribe(
      () => {
        this.toasterService.showSuccessToaster('events.report.success.download');
        this.spinnerService.deactivate();
      },
      error => {
        this.logger.error('Error while downloading Event daily summary PDF', error);
        this.toasterService.showWarningToaster('events.report.error.download');
        this.spinnerService.deactivate();
      },
    );
  }

  /**
   * Open PDF modal to configure PDF generation
   */
  openPdfReportModal(): void {
    if(this.userHasAppAccess) {
      this.exportService.checkSubscription('REPORT_PDF').pipe(
        takeUntil(this.destroy),
      ).subscribe(() => {
        if (this.exportService.checkPdfReportDuration(this.eventsFilters.dateFrom, this.eventsFilters.dateTo)) {
          const modalRef = this.modalService.open(
            PdfExportConfigurationPopupComponent,
            { centered: true },
          );
          const pdfExportConfigurationComponent: PdfExportConfigurationPopupComponent = modalRef.componentInstance;
          pdfExportConfigurationComponent.setCallbacks(
            result => {
              modalRef.close(result);
              this.getPDFReport(result);
            },
            () => {
              modalRef.dismiss();
            }
          );
        }
      });
    } else {
      this.userAppAccessService.showUpdateAppAccessModal();
    }
  }

  /**
   * Download the PDF file generated by REST API with filters
   */
  getPDFReport(title: string): void {
    this.spinnerService.activate('rotating');
    this.exportService.fetchAndSavePdfReport(
      this.eventsFilters.dateFrom,
      this.eventsFilters.dateTo,
      (this.table.rows as Event[]).map(event => event.id),
      title,
    ).pipe(
      takeUntil(this.destroy),
    ).subscribe(
      () => {
        this.toasterService.showSuccessToaster('events.report.success.download');
        this.spinnerService.deactivate();
      },
      error => {
        this.logger.error('Error while downloading Event PDF report', error);
        this.toasterService.showWarningToaster('events.report.error.download');
        this.spinnerService.deactivate();
      },
    );
  }

  /**
   * Download the CSV file generated by REST API with filters
   */
  getCsvReport(): void {
    if(this.userHasAppAccess) {
      this.exportService.checkSubscription('REPORT_CSV').pipe(
        takeUntil(this.destroy),
      ).subscribe(() => {
        this.spinnerService.activate('rotating');
        this.exportService.fetchAndSaveCsvReport(
          (this.table.rows as Event[]).map(event => event.id),
        ).pipe(
          takeUntil(this.destroy),
        ).subscribe(
          () => {
            this.spinnerService.deactivate();
          },
          error => {
            this.logger.error("Error while downloading CSV file", error);
            this.toasterService.showErrorToaster('errors.ajax.error_file');
            this.spinnerService.deactivate();
          },
        );
      });
    } else {
      this.userAppAccessService.showUpdateAppAccessModal();
    }

  }

  /**
   * Download the XLS file generated by REST API with filters
   */
  getXlsReport(): void {
    if(this.userHasAppAccess) {
      this.exportService.checkSubscription('REPORT_XLS').subscribe(() => {
        this.spinnerService.activate('rotating');
        this.exportService.fetchAndSaveXlsReport(
          this.eventsFilters.dateFrom,
          this.eventsFilters.dateTo,
          (this.table.rows as Event[]).map(event => event.id),
        ).pipe(
          takeUntil(this.destroy),
        ).subscribe(
          () => {
            this.spinnerService.deactivate();
          },
          error => {
            this.logger.error("Error while downloading XLS file", error);
            this.spinnerService.deactivate();
          },
        );
      });
    } else {
      this.userAppAccessService.showUpdateAppAccessModal();
    }
  }

  /**
    ****************************** MOBILE ONLY METHODS ******************************
   */

  /**
   * Method to check if swipped event can be approved
   * @param event Swipped event on the list
   */
  canApprove(event: Event): boolean {
    return this.userRightsService.hasRight(this.eventService.getApprovalRights(event));
  }
  /**
   * Method to check if swipped event can be rejected
   * @param event Swipped event on the list
   */
  canReject(event: Event): boolean {
    return this.userRightsService.hasRight(this.eventService.getRejectionRights(event));
  }
  /**
   * Methid to check if swipped event can be edited (or deleted)
   * @param event Swipped event on the list
   */
  canEdit(event: Event): boolean {
    return this.userRightsService.hasRight(this.eventService.getEditionRights(event));
  }

  /**
   * Method to approve event
   * @param event event to approve
   * @param item sliding item to close once event is approved
   */
  approveEvent(event: Event, item: IonItemSliding): void {
    if(this.userHasAppAccess) {
      this.eventService.approveEvent(event)
      .then(_ => {
        // Close sliding panel
        item.close();
        // Change event status to approve
        event.status = EventStatus.APPROVED;
      });
    } else {
      this.userAppAccessService.showUpdateAppAccessModal();
    }
  }

  getThumbnailUrl(attachment: Attachment, eventId: string): string {
    return this.attachmentService.getPictureUrl(this.sharedService.currentSpaceId, this.sharedService.currentSiteId, eventId, attachment.id, true);
  }

  /**
   * Method to reject event
   * @param event event to reject
   * @param item sliding item to close once event is rejected
   */
  rejectEvent(event: Event, item: IonItemSliding): void {
    this.eventService.rejectEvent(event)
      .then(_ => {
        // Close sliding panel
        item.close();
        // Change event status to rejected
        event.status = EventStatus.REJECTED;
      });
  }

  /**
   * Method to delete event
   * @param event event to delete
   * @param item sliding item to close once event is deleted
   * @param earlier optional option to tell if event has to be deleted from main list or from earlier events list
   */
  deleteEvent(event: Event, item: IonItemSliding, earlier?): void {
    event.modifiedAt = new Date();
    this.eventService.delete(event)
      .then(deletedEvent => {
        this.toasterService.showSuccessToaster('events.detail.delete.notify.success');
        return deletedEvent;
      }).catch(error => {
        this.toasterService.showErrorToaster('events.detail.delete.notify.error');
        throw error;
      })
      .then(() => {
        // Close sliding panel
        item.close();
        // If succesfully deleted -> Remove event locally
        if (earlier) {
          // if deleting an earlier event remove event from earlierEvent
          this.earlierEvents = this.earlierEvents.filter(e => e !== event);
        } else {
          // else remove event from main events list
          this.events = this.events.filter(e => e !== event);
        }
      });
  }

  loadMoreEvents(isTaskToFilter?:boolean): void {
    this.dataLoading = true;
    if(isTaskToFilter) {
      this.daysToDisplayMobile += 30;
      this.eventService.filterEventsInMobile(moment(new Date()).startOf('day').subtract(this.daysToDisplayMobile, 'days').toDate());
    }
    else {
      this.eventService.fetchNext30Events(this.shownEventsLength)
    }
  }

  swipeUp(event): void {
    // Animate arrow when distance 10 has been swiped up
    if (event.distance > 10) {
      this.swipeIconDown = true;
    }
  }

  /**
   * Show ionic confirm alert to confirm or cancel deletion of event
   */
  async showDeleteAlert(event, itemSliding, earlier?) {
    if(this.userHasAppAccess) {
      // Get translations
      const translatedAlert: any = {};
      this.translate.get([
        'events.detail.delete.question',
        'btn.cancel',
        'btn.confirm_delete',
      ]).pipe(
        takeUntil(this.destroy),
      ).subscribe(translations => {
        translatedAlert.text = translations['events.detail.delete.question'];
        translatedAlert.cancelBtn = translations['btn.cancel'];
        translatedAlert.okBtn = translations['btn.confirm_delete'];
      });
      // Show alert prompt
      const alert = await this.alertController.create({
        header: translatedAlert.text,
        buttons: [
          {
            text: translatedAlert.cancelBtn,
            role: 'cancel',
            handler: () => {
              // Close sliding item if action is canceled
              itemSliding.close();
            }
          },
          {
            text: translatedAlert.okBtn,
            cssClass: 'text-danger',
            handler: () => {
              // Delete event on confirmation
              this.deleteEvent(event, itemSliding, earlier);
            }
          }
        ]
      });
      await alert.present();
    } else {
      this.userAppAccessService.showUpdateAppAccessModal();
    }
  }

  /**
   * Show ionic confirm alert to confirm or cancel rejection of event
   */
  async showRejectAlert(event: Event, itemSliding: IonItemSliding) {
    if(this.userHasAppAccess) {
      // Get translations
      const translatedAlert: any = {};
      this.translate.get([
        'events.detail.reject.question',
        'btn.cancel',
        'events.detail.btn.reject',
        'events.detail.panel_details.rejected_reason',
      ]).pipe(
        takeUntil(this.destroy),
      ).subscribe(translations => {
        translatedAlert.text = translations['events.detail.reject.question'];
        translatedAlert.cancelBtn = translations['btn.cancel'];
        translatedAlert.okBtn = translations['events.detail.btn.reject'];
        translatedAlert.input = translations['events.detail.panel_details.rejected_reason'];
      });
      // Show alert promp
      const prompt = await this.alertController.create({
        header: translatedAlert.text,
        buttons: [
          {
            text: translatedAlert.cancelBtn,
            handler: () => {
              // Close sliding item if action is canceled
              itemSliding.close();
            }
          },
          {
            text: translatedAlert.okBtn,
            cssClass: 'text-warning',
            handler: data => {
              // Reject event on confirmation (with data from input rejectReason)
              this.rejectEvent(event, itemSliding);
            }
          }
        ]
      });
      await prompt.present();
    } else {
      this.userAppAccessService.showUpdateAppAccessModal();
    }
  }

  goToNew() {
    this.intercomService.trackUserAction(IntercomUserActions.StartingCreatingEvent);
    this.router.navigate(['../new'], { relativeTo: this.activatedRoute });
  }

  createEvent(): void {
    if(this.userHasAppAccess) {
      if (this.appUpdateSettingsService.canCreateEntites()) {
        if (this.appUpdateSettingsService.showUpdateToaster()) {
          this.toasterService.showInfoToaster("app.update.outdated");
          this.fabButtonService.click();
        }
        else {
          this.fabButtonService.click();
        }
      }
      else {
        this.toasterService.showWarningToaster("app.update.outdated.view.only");
      }
    } else {
      this.userAppAccessService.showUpdateAppAccessModal();
    }
  }

  async forceRefresh(): Promise<void> {
    await this.syncService.executeSynchronization(this.sharedService.currentSpaceId, this.sharedService.currentSiteId);
  }

  applyAllFilters() {
    this.updateEventsFilter();
  }

  updateSearchedEvents() {
    // match the search input with the current filtered data and then display only the matched data
    const filteredEvents = this.searchableFilteredEvents.filter((event) => {
      if(event) {
        return this.matchesSearchText(event,this.searchInput ? this.searchInput.value : '');
      }
      return false;
    });
    this.filteredEvents = [...filteredEvents];
    this.dataLoading = false;
  }

  clearAllFilters() {
    this.initializeFilters();
    this.searchInput.value = null;
    this.selectedTagItems = [];
    this.selectedTaskItems = [];
    this.selectedContractorItems = [];
    this.selectedCreatorItems = [];
    this.selectedLocationItem = [];
    this.selectedStatusItems = [];
    this.updateEventsFilter(true);
  }

  matchesSearchText(event: Event, searchText: string): boolean {
    const formattedSearchText = FormatSortService.formatText(searchText);
    return !searchText
      || (event.title && this.textMatches(event.title, formattedSearchText))
      || (event.createdBy && this.textMatches(event.createdBy.name, formattedSearchText));
  }

  textMatches(completeText: string, searchedText: string): boolean {
    return FormatSortService.formatText(completeText).includes(FormatSortService.formatText(searchedText));
  }

  /**
   * Check if the user get to see the custom event form popup
   */
  async checkForShowingEventFormPopup() {
    if (this.deviceService.isMobile && !this.isCustomFormOnboardingShown()) {
      if (this.isUserHasRightToEditCustomEventForm() && await this.isSiteOpenedForFirstTime()) {
        this.showCustomEventFormPopup();
      }
    }
  }

  isUserHasRightToEditCustomEventForm() {
    return this.userRightsService.hasRight(
      UserRightsService.USER_RIGHTS.space.customForm.edit
    );
  }

  /**
   * Check if custom form onboarding has been shown or not
   */
   isCustomFormOnboardingShown() {
    return JSON.parse(localStorage.getItem("isCustomFormOnboardingShown"));
  }

  /**
   * Check for If the user is opening Sample site or newly created site in the space for the first time
   */
  async isSiteOpenedForFirstTime():Promise<boolean> {
    const spaceSites = await this.siteService.getAllOnce();
    await this.getTotalEventsLength();

    return new Promise(resolve => {

      if (
        spaceSites.length === 1 &&
        spaceSites[0].name === "Sample site" &&
        this.totalEventsLength === 3
      ) {
        resolve(true);
      }

      if (
        spaceSites.length === 2 &&
        this.isSampleSitePresentInSpace(spaceSites) &&
        this.totalEventsLength === 0
        ) {
        resolve(true);
        }

        resolve(false);
    })
  }

  /**
   * Checks for sample site is present in the space or not
   */
  isSampleSitePresentInSpace(sites: Site[]) {
    const sampleSite = sites.find((site) => site.name === "Sample site");
    return sampleSite;
  }

  showCustomEventFormPopup() {
    this.dialog.open(CustomEventFormCreatorMobileComponent, {
      height: "80%",
      width: "80%",
    });
  }

  // check if error occurs in adding events
  fetchFaultyItemsCountOnError(): void {
      this.postStatusService.getPostStatus().subscribe(async val => {
        if (val.status === PostStatusStates.error) {
          this.fetchFaultyItemsCount();
          this.applyAllFilters();
        }
      })
  }

  async fetchFaultyItemsCount(): Promise<void> {
    this.numberOfFaultyItems = await this.eventService.fetchFaultyItemsCount();
  }

  hasFaultyItems(): boolean {
    if (this.numberOfFaultyItems > 0) {
      return true;
    }
    else {
      return false;
    }
  }

  routeToFaultyItems(): void {
    this.router.navigate(['space', this.sharedService.currentSpaceId, 'site', this.sharedService.currentSiteId, 'faultyItems']);
  }

  routeToFaultyItemsMobile(): void {
    this.router.navigate(['space', this.sharedService.currentSpaceId, 'site', this.sharedService.currentSiteId, 'faultyItems-mobile']);
  }

  isEventsFilterPresent(): void {
    if (this.eventsFilters.selectedContractors.length > 0 || this.eventsFilters.selectedCreators.length > 0
      || this.eventsFilters.selectedLocations.length > 0 || this.eventsFilters.selectedStatus.length > 0
      || this.eventsFilters.selectedTags.length > 0 || this.eventsFilters.selectedPeople.length > 0
      || this.eventsFilters.selectedTasks.length > 0 || this.eventsFilters.selectedEquipments.length > 0
      || this.eventsFilters.selectedMaterials.length > 0 || this.searchInput.value !== '') {
      this.showLimitedSearchBanner = true;
    }
    else {
      this.showLimitedSearchBanner = false;
    }
  }

  /**
   * This method will only run after the update to include 2FA and it's there to make sure users who update later
   * will also have the latest space data to check for 2FA authentication
   */
  checkForFetchingOfSpaceData(): void {
    let session = this.sessionService.getSession();
    if(session.is2FAAuthenticated) {
      localStorage.removeItem('fetchSpacesDataAfterUpdate');
      return;
    }
    if(localStorage.getItem('fetchSpacesDataAfterUpdate') === 'true') {
      this.authService.updateUserSessionWithUserDetails()
      .then((session) => {
        localStorage.removeItem('fetchSpacesDataAfterUpdate');
        if(session.user.isUser2FAEnabled) {
          this.authService.display2FAModals();
        }
      }).catch((error) => {
        this.logger.error('Error while updating the user session after 2FA update', error);
      });
    }
  }

  /**
   * This method will only run after the update to site archival and it's there to make sure users who update later
   * will no logner stay in archived site
   */
  checkForFetchingOfSites(): void {
    let navigateToSiteSelectScreen = false;
    if(localStorage.getItem('checkForArchivedSiteOnUpdate') === 'true') {
      this.siteService.getBackendAllSites(this.sharedService.currentSpaceId)
      .then(sites => {
        localStorage.removeItem('checkForArchivedSiteOnUpdate');
        sites.forEach(site => {
          if(site.isArchived) {
            this.siteService.delete(site, false);
            if(site.id === this.sharedService.currentSiteId) {
              navigateToSiteSelectScreen = true;
            }
          }
        });
        if(navigateToSiteSelectScreen) {
          this.router.navigate(['/space', this.sharedService.currentSpaceId , 'select-site'])
          .then(() => {
            this.userSettingsService.set('lastVisitedSite', null);
          });
        }
      })
      .catch((error) => {
        this.logger.error('Error while fetching all sites list', error);
      });
    }
  }

  getUploadCompletion(): number {
    return this.currentQueueCount / this.totalQueueCount;
  }

  getUploadCompletionPercent(): string {
    return Math.round((this.currentQueueCount / this.totalQueueCount) * 100) + '%';
  }
}
