import { Pipe, PipeTransform } from '@angular/core';
import { Event, EventStatus } from '@models/event';
import { EventsFilters } from '@models/events-filters';
import { FormatSortService } from '@services/format-sort.service';

@Pipe({
  name: 'filterEvents',
  pure: false,
})
export class FilterEvents implements PipeTransform {
  // Pipe allowing to filter events of a given date
  transform(events:  Event[], filters: EventsFilters, searchText: string): Event[] {
    return events.filter(event =>
      this.matchesSearchText(event, searchText)
      && this.matchesLocation(event, filters)
      && this.matchesTags(event, filters)
      && this.matchesContractors(event, filters)
      && this.matchesPeople(event, filters)
      && this.matchesEquipment(event, filters)
      && this.matchesMaterial(event, filters)
      && this.matchesStatus(event, filters)
      && this.matchesLinkedTasks(event, filters)
      && this.matchesCreators(event, filters)
    );
  }

  // Pipe allowing to filter events for a given date range
  transformOnDates(events: Event[], filters: EventsFilters, searchText: string,
    fromDate: Date, toDate: Date): Event[] {

    return this.transform(events, filters, searchText).filter(event =>
      this.matchesDateRange(new Date(event.startDatetime), fromDate, toDate)
    );
  }

  // For filtering events in a given date range based on the event start date
  private matchesDateRange(eventStartDatetime: Date, fromDate: Date, toDate: Date): boolean {
    return eventStartDatetime >= fromDate && eventStartDatetime <= toDate;
  }

  private matchesSearchText(event: Event, searchText: string): boolean {
    const formattedSearchText = FormatSortService.formatText(searchText);
    return !searchText
      || (event.title && this.textMatches(event.title, formattedSearchText))
      || (event.createdBy && this.textMatches(event.createdBy.name, formattedSearchText));
  }

  private matchesLocation(event: Event, filters: EventsFilters): boolean {
    return filters.selectedLocations.length === 0
      || filters.selectedLocations.some(location => event.locationObject && (event.locationObject.id === location.id));
  }

  private matchesTags(event: Event, filters: EventsFilters): boolean {
    return filters.selectedTags.length === 0
      || event.tags.some(tag => filters.selectedTags.some(selectedTag => tag.tagId === selectedTag.id));
  }

  private matchesContractors(event: Event, filters: EventsFilters): boolean {
    return filters.selectedContractors.length === 0
      || event.contractors.some(contractor => filters.selectedContractors.some(selectedContractor =>
        contractor.contractorId === selectedContractor.id));
  }

  private matchesPeople(event : Event, filters: EventsFilters): boolean {
    return filters.selectedPeople.length === 0
      || event.assets.some(asset => filters.selectedPeople.some(people => 
        asset.assetId === people.id
      ))
  }

  private matchesEquipment(event : Event, filters: EventsFilters): boolean {
    return filters.selectedEquipments.length === 0
      || event.assets.some(asset => filters.selectedEquipments.some(equipment => 
        asset.assetId === equipment.id
      ))
  }

  private matchesMaterial(event : Event, filters: EventsFilters): boolean {
    return filters.selectedMaterials.length === 0
      || event.assets.some(asset => filters.selectedMaterials.some(material => 
        asset.assetId === material.id
      ))
  }

  private matchesStatus(event: Event, filters: EventsFilters): boolean {
    return filters.selectedStatus.length === 0
      || filters.selectedStatus.some(status => event.status === status);
  }

  private matchesLinkedTasks(event: Event, filters: EventsFilters): boolean {
    return filters.selectedTasks.length === 0
      || filters.selectedTasks.some(task => event.task.taskId === task.id);
  }

  private matchesCreators(event: Event, filters: EventsFilters): boolean {
    return filters.selectedCreators.length === 0
      || filters.selectedCreators.some(creator => creator.id === event.createdBy.id);
  }

  private textMatches(completeText: string, searchedText: string): boolean {
    return FormatSortService.formatText(completeText).includes(FormatSortService.formatText(searchedText));
  }
}
