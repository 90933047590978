<ion-header class="secondary-header" mode="ios">
  <app-mobile-toolbar
       doneLabel="apply"
       title="label.filters"
       (back)="resetFilters(); modalCtrl.dismiss()"
       (done)="callMobileFiltersFunction(); modalCtrl.dismiss()">
  </app-mobile-toolbar>
</ion-header>


<ion-content class="main-form-wrapper">
  <ion-grid class="scrollable-content overflow-auto no-weird-margin" [ngSwitch]="currentFilter" no-padding no-margin>
    <!-- FILTERS -->

    <!-- TAGS -->
    <ion-item lines="none" (click)="updateCurrentTab('tags')" class="form-input item-interactive">
      <ion-col class="form-input-icon" no-padding size="1">
        <ion-label color="medium">
          <ion-icon mode="md" [color]="eventsFilters.selectedTags.length > 0 ? 'primary' : 'secondary'" class="pull-left" name="tag"></ion-icon>
        </ion-label>
      </ion-col>
      <ion-col no-padding size="11" *ngIf="tagsLoaded">
        <ion-text class="placeholder" *ngIf="eventsFilters.selectedTags.length === 0">
          {{ 'mobile-placeholder-tags' | translate }}
        </ion-text>
        <ion-text *ngIf="eventsFilters.selectedTags.length > 0">
          {{ eventsFilters.selectedTags | listToLabelList | join }}
        </ion-text>
      </ion-col>
    </ion-item>
    <app-resource-selector
      *ngSwitchCase="'tags'"
      [items]="tags"
      [(selectedItems)]="eventsFilters.selectedTags">
    </app-resource-selector>
    <!-- END OF TAGS -->

    <!-- STATUS -->
    <ion-item (click)="updateCurrentTab('status')" class="form-input item-interactive">
      <ion-col class="form-input-icon" no-padding size="1">
        <ion-label color="medium">
          <ion-icon mode="md" [color]="eventsFilters.selectedStatus.length > 0 ? 'primary' : 'secondary'" class="pull-left" name="state"></ion-icon>
        </ion-label>
      </ion-col>
      <ion-col no-padding size="11">
        <ion-text class="placeholder" *ngIf="eventsFilters.selectedStatus.length === 0">
          {{ 'filter.all_status' | translate }}
        </ion-text>
        <ion-text *ngIf="eventsFilters.selectedStatus.length > 0">
          {{ eventsFilters.selectedStatus | join }}
        </ion-text>
      </ion-col>
    </ion-item>

    <div class="w-100 status-selector" *ngSwitchCase="'status'">
      <ion-item lines="none" class="list-item ml-2" *ngFor="let status of statuses">
        <ion-label>{{ status | translate }}</ion-label>
        <ion-checkbox
          [checked]="eventsFilters.selectedStatus.includes(status)"
          (ionChange)="toggleStatus(status)"
          slot="start">
        </ion-checkbox>
      </ion-item>
    </div>
    <!-- END OF STATUS -->

    <!-- CONTRACTORS -->
    <ion-item lines="none" (click)="updateCurrentTab('contractors')" class="form-input item-interactive">
      <ion-col class="form-input-icon" no-padding size="1">
        <ion-label color="medium">
          <ion-icon mode="md" [color]="eventsFilters.selectedContractors.length > 0 ? 'primary' : 'secondary'" class="pull-left" name="suitcase"></ion-icon>
        </ion-label>
      </ion-col>
      <ion-col no-padding size="11" *ngIf="contractorsLoaded">
        <ion-text class="placeholder" *ngIf="eventsFilters.selectedContractors.length === 0">
          {{ 'mobile-placeholder-contractors' | translate }}
        </ion-text>
        <ion-text *ngIf="eventsFilters.selectedContractors.length > 0">
          {{ eventsFilters.selectedContractors | listToLabelList | join }}
        </ion-text>
      </ion-col>
    </ion-item>
    <app-resource-selector
      *ngSwitchCase="'contractors'"
      [items]="contractors"
      [(selectedItems)]="eventsFilters.selectedContractors">
    </app-resource-selector>
    <!-- END OF CONTRACTORS -->

    <!-- TASKS -->
    <ion-item lines="none" (click)="updateCurrentTab('tasks')" class="form-input item-interactive">
      <ion-col class="form-input-icon" no-padding size="1">
        <ion-label color="medium">
          <ion-icon mode="md" [color]="eventsFilters.selectedTasks.length > 0 ? 'primary' : 'secondary'" class="pull-left" name="task-link"></ion-icon>
        </ion-label>
      </ion-col>
      <ion-col no-padding size="11" *ngIf="tasksLoaded">
        <ion-text class="placeholder" *ngIf="eventsFilters.selectedTasks.length === 0">
          {{ 'mobile-placeholder-task' | translate }}
        </ion-text>
        <ion-text *ngIf="eventsFilters.selectedTasks.length > 0">
          {{ eventsFilters.selectedTasks | taskToLabelList | join }}
        </ion-text>
      </ion-col>
    </ion-item>
    <app-resource-selector
      *ngSwitchCase="'tasks'"
      [items]="tasks"
      [(selectedItems)]="eventsFilters.selectedTasks">
    </app-resource-selector>
    <!-- END OF TASKS -->

    <!-- LOCATIONS -->
    <ion-item lines="none" (click)="updateCurrentTab('locations')" class="form-input item-interactive">
      <ion-col class="form-input-icon" no-padding size="1">
        <ion-label color="medium">
          <ion-icon mode="md" [color]="eventsFilters.selectedLocations.length > 0 ? 'primary' : 'secondary'" class="pull-left" name="location-pin"></ion-icon>
        </ion-label>
      </ion-col>
      <ion-col no-padding size="11" *ngIf="locationsLoaded">
        <ion-text class="placeholder" *ngIf="eventsFilters.selectedLocations.length === 0">
          {{ 'mobile-placeholder-location-filter' | translate }}
        </ion-text>
        <ion-text *ngIf="eventsFilters.selectedLocations.length > 0">
          {{ eventsFilters.selectedLocations | listToLabelList | join }}
        </ion-text>
      </ion-col>
    </ion-item>
    <app-resource-selector
      *ngSwitchCase="'locations'"
      [items]="locations"
      [(selectedItems)]="eventsFilters.selectedLocations">
    </app-resource-selector>
    <!-- END OF LOCATIONS -->

    <!-- PEOPLE -->
    <ion-item lines="none" (click)="updateCurrentTab(assetCategoryNames.PEOPLE)" class="form-input item-interactive">
      <ion-col class="form-input-icon" no-padding size="1">
        <ion-label color="medium">
          <ion-icon mode="md" [color]="eventsFilters.selectedPeople.length > 0 ? 'primary' : 'secondary'" class="pull-left" name="people-outline"></ion-icon>
        </ion-label>
      </ion-col>
      <ion-col no-padding size="11" *ngIf="peopleLoaded">
        <ion-text class="placeholder" *ngIf="eventsFilters.selectedPeople.length === 0">
          {{ 'mobile-placeholder-labours' | translate }}
        </ion-text>
        <ion-text *ngIf="eventsFilters.selectedPeople.length > 0">
          {{ eventsFilters.selectedPeople | listToLabelList | join }}
        </ion-text>
      </ion-col>
    </ion-item>
    <app-resource-selector
      *ngSwitchCase="'people'"
      [items]="people"
      [(selectedItems)]="eventsFilters.selectedPeople">
    </app-resource-selector>
    <!-- END OF PEOPLE -->

    <!-- EQUIPMENT -->
    <ion-item lines="none" (click)="updateCurrentTab(assetCategoryNames.EQUIPMENTS)" class="form-input item-interactive">
      <ion-col class="form-input-icon" no-padding size="1">
        <ion-label color="medium">
          <ion-icon mode="md" [color]="eventsFilters.selectedEquipments.length > 0 ? 'primary' : 'secondary'" class="pull-left" name="hammer-outline"></ion-icon>
        </ion-label>
      </ion-col>
      <ion-col no-padding size="11" *ngIf="equipmentsLoaded">
        <ion-text class="placeholder" *ngIf="eventsFilters.selectedEquipments.length === 0">
          {{ 'mobile-placeholder-plants' | translate }}
        </ion-text>
        <ion-text *ngIf="eventsFilters.selectedEquipments.length > 0">
          {{ eventsFilters.selectedEquipments | listToLabelList | join }}
        </ion-text>
      </ion-col>
    </ion-item>
    <app-resource-selector
      *ngSwitchCase="'equipments'"
      [items]="equipment"
      [(selectedItems)]="eventsFilters.selectedEquipments">
    </app-resource-selector>
    <!-- END OF EQUIPMENT -->

    <!-- MATERIALS -->
    <ion-item lines="none" (click)="updateCurrentTab(assetCategoryNames.MATERIALS)" class="form-input item-interactive">
      <ion-col class="form-input-icon" no-padding size="1">
        <ion-label color="medium">
          <ion-icon mode="md" [color]="eventsFilters.selectedMaterials.length > 0 ? 'primary' : 'secondary'" class="pull-left" name="apps-outline"></ion-icon>
        </ion-label>
      </ion-col>
      <ion-col no-padding size="11" *ngIf="materialsLoaded">
        <ion-text class="placeholder" *ngIf="eventsFilters.selectedMaterials.length === 0">
          {{ 'mobile-placeholder-materials' | translate }}
        </ion-text>
        <ion-text *ngIf="eventsFilters.selectedMaterials.length > 0">
          {{ eventsFilters.selectedMaterials | listToLabelList | join }}
        </ion-text>
      </ion-col>
    </ion-item>
    <app-resource-selector
      *ngSwitchCase="'materials'"
      [items]="material"
      [(selectedItems)]="eventsFilters.selectedMaterials">
    </app-resource-selector>
    <!-- END OF MATERIALS -->


    <!-- CREATORS -->
    <ion-item lines="none" (click)="updateCurrentTab('creators')" class="form-input item-interactive">
      <ion-col class="form-input-icon" no-padding size="1">
        <ion-label color="medium">
          <ion-icon mode="md" [color]="eventsFilters.selectedCreators.length > 0 ? 'primary' : 'secondary'" class="pull-left" name="engineer"></ion-icon>
        </ion-label>
      </ion-col>
      <ion-col no-padding size="11" *ngIf="creatorsLoaded">
        <ion-text class="placeholder" *ngIf="eventsFilters.selectedCreators.length === 0">
            {{'mobile-placeholder-creators' | translate}}
        </ion-text>
        <ion-text *ngIf="eventsFilters.selectedCreators.length > 0">
          {{ eventsFilters.selectedCreators | listToLabelList | join }}
        </ion-text>
      </ion-col>
    </ion-item>
    <app-resource-selector
      *ngSwitchCase="'creators'"
      [items]="creators"
      [(selectedItems)]="eventsFilters.selectedCreators">
    </app-resource-selector>
    <!-- END OF CREATORS -->

    <ion-item (click)="resetFilters()" class="mt-3">
      <ion-col size="1">
        <ion-icon mode="md" color="primary" class="pull-left small" name="cross"></ion-icon>
      </ion-col>
      <ion-col size="11">
        <ion-label color="primary">{{ 'filter.remove.all' | translate }}</ion-label>
      </ion-col>
    </ion-item>

  <!-- END FILTERS -->

  </ion-grid>
</ion-content>
